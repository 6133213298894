import ErrorPages from './ErrorRoutes';
import Example from './Example';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Welcome from './Welcome';
import SuccessUpdate from './SuccessUpdate';
import SuccessFeedback from './SuccessFeedback';
import AccountDeletedSuccess from './AccountDeletedSuccess';
import HowToRecord from './HowToRecord';
import AccessibilityPage from './AccessibilityPage';
import DecideRole from './DecideRole';
import { OrganisationLogin } from './Login';
import SongForLife from './SongForLife';

export default {
  ErrorPages,
  Example,
  Login,
  ForgotPassword,
  ResetPassword,
  Welcome,
  SuccessUpdate,
  SuccessFeedback,
  AccountDeletedSuccess,
  HowToRecord,
  AccessibilityPage,
  DecideRole,
  OrganisationLogin,
  SongForLife,
};
