import axios from 'axios';
import handleError from './format-error';

const ORGANISATIONS_BASE = '/organisations';

const getOrganisationById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${ORGANISATIONS_BASE}/${id}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const getOrganisationByUniqueSlug = async ({ uniqueSlug, options }) => {
  try {
    const { data } = await axios.get(
      `${ORGANISATIONS_BASE}/unique-slug/${uniqueSlug}`
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getOrganisationByName = async ({ name, options }) => {
  try {
    const { data } = await axios.get(`${ORGANISATIONS_BASE}/name/${name}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateOrganisation = async ({ id, form, options }) => {
  try {
    const { data } = await axios.patch(`${ORGANISATIONS_BASE}/${id}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const getTherapistsByOrganisationId = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${ORGANISATIONS_BASE}/${id}/therapists`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getOrganisations = async () => {
  try {
    const { data } = await axios.get(`${ORGANISATIONS_BASE}/`);
    return data;
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

export {
  getOrganisationById,
  getTherapistsByOrganisationId,
  updateOrganisation,
  getOrganisations,
  getOrganisationByUniqueSlug,
  getOrganisationByName,
};
