import styled from '@emotion/styled';
import { H4 } from '../Typography';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../theme';

export const Div = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  width: 500px;
  flex-wrap: wrap;
  margin-top: 0;
  height: 48px;
  position: relative;
  z-index: 2;
  padding-left: 117px;
  background-color: ${theme.colors.gray9};
`;
export const Div1 = styled.div`
  background-color: ${theme.colors.gray9};
`;
export const Head4 = styled(H4)`
  display: inline;
`;

export const Link = styled(RouterLink)`
  font-family: Lato;
  font-weight: bold;
  font-size: 1rem;
  line-height: 150%;
  width: 200px;
  color: ${theme.colors.white};
`;
