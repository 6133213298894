const Document = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      d="M38.43 12.913V32.65a1.563 1.563 0 01-1.555 1.556H3.125a1.562 1.562 0 01-1.557-1.556V7.35a1.562 1.562 0 011.557-1.556h10.587a1.563 1.563 0 011.369.812l2.156 3.938a1.543 1.543 0 001.363.806h18.275a1.562 1.562 0 011.556 1.563z"
      fill="#F5D145"
    />
    <path
      d="M38.43 12.913V32.65a1.563 1.563 0 01-1.555 1.556H3.125a1.562 1.562 0 01-1.557-1.556V7.35a1.562 1.562 0 011.557-1.556h10.587a1.563 1.563 0 011.369.812l2.156 3.938a1.543 1.543 0 001.363.806h18.275a1.562 1.562 0 011.556 1.563v0zM8.38 17.375h23.238M8.38 22.694h23.238M8.38 28.013h23.238"
      stroke="#0E0F11"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M6.25 5.794H3.125A1.563 1.563 0 001.568 7.35v25.3a1.563 1.563 0 001.557 1.556H6.25a1.562 1.562 0 01-1.557-1.556V7.35A1.563 1.563 0 016.25 5.794z"
        fill="#0E0F11"
        opacity=".25"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M38.431 12.912a1.563 1.563 0 00-1.556-1.562H33.75a1.562 1.562 0 011.556 1.563V32.65a1.563 1.563 0 01-1.556 1.556h3.125a1.562 1.562 0 001.556-1.556V12.913z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Document;
