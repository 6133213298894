import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import FacebookIcon from '../../../components/assets/facebook.png';
import TwitterIcon from '../../../components/assets/twitter.png';
import WhatsappIcon from '../../../components/assets/whatsapp.png';
import EmailIcon from '../../../components/assets/mail.png';

import { Grid, Typography as T } from '../../../components';

import * as S from './style';
const { Col, Row } = Grid;

const ShareBox = ({ url }) => {
  return (
    <Row mt="6" mb="6">
      <Col w={[4, 12, 12]}>
        <T.H3 weight="bold" color="gray10" mb="4">
          Want to share your Song for Life card?
        </T.H3>
      </Col>
      <Col w={[4, 12, 12]}>
        <S.Box maxWidth="400">
          <T.P small color="neutral90" mr="4">
            Share on:
          </T.P>
          <S.SocialWrapper>
            <FacebookShareButton
              url={url}
              quote="I have just created my Song for Life card"
            >
              <S.SocialImg alt="facebook" src={FacebookIcon} />
            </FacebookShareButton>
            <TwitterShareButton
              url={url}
              title="I have just created my Song for Life card"
            >
              <S.SocialImg alt="twitter" src={TwitterIcon} />
            </TwitterShareButton>
            <WhatsappShareButton
              url={url}
              title="I have just created my Song for Life card"
            >
              <S.SocialImg alt="whatsapp" src={WhatsappIcon} />
            </WhatsappShareButton>
            <EmailShareButton
              url={url}
              subject="Your Song for Life Card"
              body={`Here is your song for life card. Download it and keep it safe in case you find yourself in times of trouble. Don't forget to share it with your friends and family.
Want to find out more about music therapy? Visit: https://chilternmusictherapy.co.uk


open the link in the browser: 
`}
            >
              <S.SocialImg alt="email" src={EmailIcon} />
            </EmailShareButton>
          </S.SocialWrapper>
        </S.Box>
      </Col>
    </Row>
  );
};

export default ShareBox;
