import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useOrg } from '../../../context/organisation';
import AccessibilityBar from '../../AccessibilityBar';
import * as S from './style';
import GoBack from '../../GoBack';
import theme from '../../../theme';
import { useMediaQuery } from 'react-responsive';
import CustomLogo from '../../../components/CustomLogo';
import Footer from '../../Footer';

const Onboarding = ({ children, goBack, ...props }) => {
  const { org } = useOrg();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  return (
    <S.Container>
      <S.Wrapper>
        {!isMobile && <AccessibilityBar />}
        <S.OnboardingHeader>
          <Link to="/" aria-label="Home Page" style={{ maxWidth: '120px' }}>
            <CustomLogo url={org?.logoUrl} />
          </Link>
        </S.OnboardingHeader>
      </S.Wrapper>
      <S.Content>
        {goBack && (
          <GoBack
            mb="6"
            mbM="5"
            ml={`${theme.constants.gridGutter.desktop / 2}px`}
            mlT={`${theme.constants.gridGutter.tablet / 2}px`}
            mlM={`${theme.constants.gridGutter.mobile / 2}px`}
          />
        )}
        {children}
      </S.Content>
      <Footer />
    </S.Container>
  );
};

Onboarding.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Onboarding;
