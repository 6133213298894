const Audio = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      d="M16.796 6.585a14.382 14.382 0 00-6.354 4.37c-4.123 4.702-4.83 12.143-2.252 19.57l.62 3.163-.38.247C2.817 25.273 2.513 15.17 7.674 8.738a17.12 17.12 0 018.995-5.782 1.715 1.715 0 00-.304.974v1.532a1.66 1.66 0 00.43 1.123zM31.58 33.957l-.346-.248.621-3.155c2.556-7.449 1.85-14.89-2.273-19.62a14.346 14.346 0 00-6.298-4.342c.299-.309.463-.722.46-1.151V3.93a1.752 1.752 0 00-.283-.947c3.487.937 6.59 2.95 8.867 5.754 5.154 6.432 4.857 16.535-.748 25.219z"
      fill="#CB4394"
    />
    <path
      d="M12.172 35.05l-1.363.27a1.668 1.668 0 01-1.955-1.321l-.057-.29-.621-3.162-.706-3.573a1.659 1.659 0 011.313-1.956l1.363-.268 2.026 10.3z"
      fill="#5E6875"
    />
    <path
      d="M14.091 35.404a3.792 3.792 0 01-1.87-.099l-.05-.254-2.019-10.322-.05-.247a3.75 3.75 0 011.695-.805 3.82 3.82 0 014.476 3.015l.826 4.236a3.803 3.803 0 01-3.008 4.476z"
      fill="#D0D4D9"
    />
    <path
      d="M32.526 26.953l-.706 3.58-.586 3.177-.057.289a1.666 1.666 0 01-1.955 1.32l-1.356-.268 1.956-10.315 1.355.261a1.668 1.668 0 011.349 1.956z"
      fill="#5E6875"
    />
    <path
      d="M29.9 24.482l-.05.254-2.02 10.315-.049.254a3.819 3.819 0 01-4.885-4.377l.833-4.236a3.806 3.806 0 016.17-2.21zM23.743 3.93v1.532a1.63 1.63 0 01-.459 1.15 1.665 1.665 0 01-1.242.552h-3.968a1.702 1.702 0 01-1.278-.58 1.66 1.66 0 01-.43-1.122V3.93a1.694 1.694 0 011.708-1.701h3.982a1.722 1.722 0 011.412.755c.18.283.276.61.275.946z"
      fill="#D0D4D9"
    />
    <path
      d="M16.796 6.585a14.382 14.382 0 00-6.354 4.37c-4.123 4.702-4.83 12.143-2.252 19.57l.62 3.163-.38.247C2.817 25.273 2.513 15.17 7.674 8.738a17.12 17.12 0 018.995-5.782 1.715 1.715 0 00-.304.974v1.532a1.66 1.66 0 00.43 1.123zM31.58 33.957l-.346-.248.621-3.155c2.556-7.449 1.85-14.89-2.273-19.62a14.346 14.346 0 00-6.298-4.342c.299-.309.463-.722.46-1.151V3.93a1.752 1.752 0 00-.283-.947c3.487.937 6.59 2.95 8.867 5.754 5.154 6.432 4.857 16.535-.748 25.219z"
      stroke="#0E0F11"
      strokeWidth="1.412"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.172 35.05l-1.363.27a1.668 1.668 0 01-1.955-1.321l-.057-.29-.621-3.162-.706-3.573a1.659 1.659 0 011.313-1.956l1.363-.268 2.026 10.3z"
      stroke="#0E0F11"
      strokeWidth="1.412"
      strokeMiterlimit="10"
    />
    <path
      d="M14.091 35.404a3.792 3.792 0 01-1.87-.099l-.05-.254-2.019-10.322-.05-.247a3.75 3.75 0 011.695-.805 3.82 3.82 0 014.476 3.015l.826 4.236a3.803 3.803 0 01-3.008 4.476v0zM32.526 26.953l-.706 3.58-.586 3.177-.057.289a1.666 1.666 0 01-1.955 1.32l-1.356-.268 1.956-10.315 1.355.261a1.668 1.668 0 011.349 1.956v0z"
      stroke="#0E0F11"
      strokeWidth="1.412"
      strokeMiterlimit="10"
    />
    <path
      d="M29.9 24.482l-.05.254-2.02 10.315-.049.254a3.819 3.819 0 01-4.885-4.377l.833-4.236a3.806 3.806 0 016.17-2.21v0zM23.743 3.93v1.532a1.63 1.63 0 01-.459 1.15 1.665 1.665 0 01-1.242.552h-3.968a1.702 1.702 0 01-1.278-.58 1.66 1.66 0 01-.43-1.122V3.93a1.694 1.694 0 011.708-1.701h3.982a1.722 1.722 0 011.412.755c.18.283.276.61.275.946v0z"
      stroke="#0E0F11"
      strokeWidth="1.412"
      strokeMiterlimit="10"
    />
    <path
      d="M32.327 8.738a17.889 17.889 0 00-5.189-4.307 12.634 12.634 0 011.688 3.276 16.73 16.73 0 01.706 3.184l.056.064c4.123 4.709 4.83 12.15 2.238 19.578l.706-3.58a1.666 1.666 0 00-1.299-1.956l-1.412-.26.05-.255a3.777 3.777 0 00-2.874-.84 1.03 1.03 0 00-.402.445 9.306 9.306 0 011.447 7.766 9.178 9.178 0 01-1.906 3.58c.558.079 1.127.036 1.666-.128l.05-.254 1.355.268A1.667 1.667 0 0031.163 34l.056-.29.381.248c5.585-8.684 5.881-18.787.727-25.219z"
      fill="#0E0F11"
      opacity=".25"
    />
    <path
      d="M10.004 32.375a5.436 5.436 0 01-.064-4.568c.412-.616.656-1.329.706-2.068a3.7 3.7 0 00-.494-1.243l.05.247-1.363.269a1.66 1.66 0 00-1.314 1.955l.706 3.573c-1.885-5.437-2.005-10.873-.324-15.243-.301-1.44-.292-2.928.028-4.363a10.731 10.731 0 013.53-5.648A16.57 16.57 0 007.85 8.519l-.176.219c-5.161 6.432-4.858 16.535.741 25.219l.381-.247.057.289a1.666 1.666 0 001.955 1.32l.706-.14a27.342 27.342 0 00-1.51-2.804z"
      fill="#FCFCFC"
      opacity=".5"
    />
  </svg>
);

export default Audio;
