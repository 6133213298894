import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';

import { Typography as T, Button, Grid } from '../../../components';

import { navRoutes } from '../../../constants';
import { Programmes } from '../../../api-calls';
import { BasicInput } from '../../../components/Inputs';
import validate from '../../../validation/schemas/create-protected-programme';
import Copy from '../../../components/Copy';
import Title from '../../../components/Title';
import Modal from '../../../components/Modal';

const { Row, Col } = Grid;

const ShareProgramme = () => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [programme, setProgramme] = useState({ client: {} });
  const [passwordError, setPasswordError] = useState('');
  const [httpError, setHttpError] = useState('');
  const location = useLocation();

  const [updatePassword, setUpdatePassword] = useState(
    location?.state?.updatePassword
  );

  const submitAttempt = useRef();
  const { id } = useParams();

  const validateForm = () => {
    try {
      validate({
        password,
      });
      setPasswordError('');
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setPasswordError(error.inner.password);
      }
      return false;
    }
  };

  const handleUpdatePassword = async () => {
    const valid = validateForm();
    if (!valid) return;

    setLoading(true);
    const { error, data } = await Programmes.updateProgrammePassword({
      id,
      password,
      action: 'UPDATE',
    });

    setLoading(false);
    if (error) {
      setHttpError(error.message);
    } else {
      setIsModalOpen(true);
      setToken(data.token);
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    const getProgramme = async () => {
      const { error, data } = await Programmes.getProgrammeById({
        id,
      });

      if (!error) {
        setProgramme(data);
        setToken(data.token);
      }
    };
    getProgramme();
  }, [id]);

  return (
    <>
      <Title boldSection="Share" lightSection="programme" mbM="3" boldFirst />

      {!token && (
        <Row>
          <Col w={[4, 6, 8]}>
            <T.P color="gray8">
              Share your programme with your client by sending them a password
              protected link.
            </T.P>
          </Col>
        </Row>
      )}

      {token && (
        <>
          <Row mt={5}>
            <Col w={[4, 6, 6]}>
              <T.P color="gray8">
                Here’s your password protected link. For safety reasons this
                password will expire and need to be updated after every 30 days.
              </T.P>
              {programme?.passwordExpiresAt &&
                moment(programme.passwordExpiresAt).isSameOrAfter(moment()) && (
                  <T.P color="gray9" mt="3" weight="bold">
                    This password will expire{' '}
                    {moment(programme.passwordExpiresAt).fromNow()}.
                  </T.P>
                )}
              {programme?.passwordExpiresAt &&
                moment(programme.passwordExpiresAt).isBefore(moment()) && (
                  <T.P color="error">This password has expired.</T.P>
                )}
            </Col>
          </Row>

          <Row>
            <Col w={[4, 12, 6]} mt={5}>
              <Copy
                inviteToken={`${
                  window.location.origin
                }${navRoutes.CLIENT.PROTECTED_PROGRAMME.replace(
                  ':id',
                  id
                ).replace(':token?', token)}`}
              />
            </Col>
          </Row>
        </>
      )}

      {!updatePassword && token ? (
        <Row>
          <Col w={[4, 12, 6]} mt={5}>
            <T.Link
              onClick={() => setUpdatePassword(true)}
              underline
              color="gray10"
              weight="bold"
            >
              Update Password
            </T.Link>{' '}
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col w={[4, 12, 6]} mt={5}>
              <BasicInput
                label={token ? 'Update password' : 'Create password'}
                placeholder="Type password here..."
                type="password"
                value={password}
                handleChange={(value) => setPassword(value)}
                error={passwordError}
              />
            </Col>
          </Row>
          <Row>
            <Col w={[4, 12, 6]} mt={5}>
              {httpError && <T.P color="error">{httpError}</T.P>}

              <Button
                variant="secondary"
                text={token ? 'Update password' : 'Create password'}
                onClick={handleUpdatePassword}
                loading={loading}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col w={[4, 12, 6]} mt={7}>
          <Button
            variant="primary"
            text="Return home"
            to={navRoutes.THERAPIST.SINGLE_PROGRAMME.replace(':id', id)}
          />
        </Col>
      </Row>
      <Modal
        visible={isModalOpen}
        setIsModalVisible={setIsModalOpen}
        parentFunc={() => {
          setUpdatePassword(false);
        }}
        type="general"
      >
        <T.H3 mb="2">Successfully updated</T.H3>
        <T.P color="gray9" mb="5">
          The password for this programme has been updated
        </T.P>
        <Button
          handleClick={() => {
            setUpdatePassword(false);
            setIsModalOpen(false);
            setPassword('');
          }}
          text="Okay"
        />
      </Modal>
    </>
  );
};

export default ShareProgramme;
