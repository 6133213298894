import {
  navRoutes,
  userRoles,
  organisationsStatuses,
} from '../../../constants';
import { useAuth } from '../../../context/auth';
import { useOrg } from '../../../context/organisation';

import { Copy, Title, Typography as T, Cards, Grid } from '../../../components';

const { Row, Col } = Grid;
const { Info, Link } = Cards;

const Dashboard = () => {
  const { org } = useOrg();
  const { user } = useAuth();

  return (
    <>
      <Row mb="12">
        <Col w={[4, 12, 8]}>
          <Title
            boldSection="back"
            lightSection="Welcome"
            w={[4, 12, 12]}
            inner
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <Info
            title="How does this tool work? "
            body={
              <T.Link
                to={navRoutes.ADMIN.HOW_IT_WORKS}
                underline="true"
                weight="bold"
              >
                Click to read more{' '}
              </T.Link>
            }
          />
        </Col>
      </Row>
      {user.role === userRoles.ADMIN &&
        (org.status === organisationsStatuses.ACTIVE ? (
          <>
            {' '}
            <Row mtT="5">
              <Col w={[4, 6, 8]}>
                <T.H3 color="gray10" weight="bold">
                  Your unique link
                </T.H3>
              </Col>
            </Row>
            <Row mb="4">
              <Col w={[4, 6, 6]}>
                <T.P color="gray8">
                  This is the link you will need to share with your therapists
                  to sign up to the platform.
                </T.P>
              </Col>
            </Row>
            <Row mb="7">
              <Col w={[4, 6, 4]}>
                <Copy
                  inviteToken={`${
                    window.location.origin
                  }${navRoutes.THERAPIST.SIGNUP.replace(
                    ':uniqueSlug',
                    org.uniqueSlug
                  )}`}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row mb="8">
            <Col w={[4, 6, 6]}>
              <T.P color="gray8">This account is currently being reviewed</T.P>
            </Col>
          </Row>
        ))}
      {user.role === userRoles.SUPER_ADMIN ? (
        <>
          <Row mb="6" mt="4" mtM="4">
            <Col w={[4, 6, 4]}>
              <Link
                variant="graphic1"
                to={navRoutes.SUPER_ADMIN.ALL_ORGANISATIONS}
                title="Accounts"
                customText="All"
              />
            </Col>
          </Row>
          <Row mb="6">
            <Col w={[4, 6, 4]}>
              <Link
                variant="graphic2"
                to={navRoutes.ADMIN.ALL_CONTENT}
                title="Content"
                customText="All"
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row mb="6" mt="4" mtM="4">
            <Col w={[4, 6, 4]}>
              <Link
                variant="graphic1"
                to={navRoutes.ADMIN.MY_THERAPISTS}
                title="Therapists"
              />
            </Col>
          </Row>
          <Row mb="6">
            <Col w={[4, 6, 4]}>
              <Link
                variant="graphic2"
                to={navRoutes.ADMIN.ALL_CONTENT}
                title="Content"
                customText="All"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Dashboard;
