import { navRoutes } from '../../../constants';
import {
  Grid,
  TextWithIcon,
  Button,
  Typography as T,
  Title,
} from '../../../components';
import * as S from './style';

const { Row, Col } = Grid;

const HowItWorks = () => {
  return (
    <S.Wrapper>
      <Title lightSection="How it " boldSection="works" mbM="3" />
      <Row mb="4" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            On this page you can find useful links to user guide(s) and videos
            to make sure you are making the most of this tool for your
            therapists and their clients.
          </T.P>
        </Col>
      </Row>

      <Row mb="4" mbM="4">
        <Col w={[4, 12, 12]}>
          <TextWithIcon
            to={navRoutes.EXTERNAL.HOW_IT_WORKS_VIDEO}
            external="true"
            underline="true"
            text="View video"
            icon="forwardArrow"
            weight="bold"
          />
        </Col>
      </Row>

      <Row mb="4" mbM="4">
        <Col w={[4, 12, 12]}>
          <TextWithIcon
            to={navRoutes.EXTERNAL.USER_GUIDE_CLIENT}
            external="true"
            underline="true"
            text="Client guide"
            icon="forwardArrow"
            weight="bold"
          />
          <TextWithIcon
            to={navRoutes.EXTERNAL.USER_GUIDE_THERAPIST}
            external="true"
            underline="true"
            text="Therapist guide"
            icon="forwardArrow"
            weight="bold"
          />
          <TextWithIcon
            to={navRoutes.EXTERNAL.USER_GUIDE_ORGANISATION}
            external="true"
            underline="true"
            text="Organisation guide"
            icon="forwardArrow"
            weight="bold"
          />
        </Col>
      </Row>
      <Row mt="6" mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.H3 color="gray8" weight="bold">
            Still need help?
          </T.H3>
          <T.P color="gray8">
            Contact the Duet team at digital@duetplatform.com
          </T.P>
        </Col>
      </Row>
      <S.ButtonWrapper>
        <Col w={[4, 8, 8]} mt="6">
          <Button to={navRoutes.ADMIN.SIGNUP} text="Return home" />
        </Col>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default HowItWorks;
