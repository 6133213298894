import { useState, useEffect } from 'react';
import {
  Button,
  Typography as T,
  Grid,
  Inputs,
  TextWithIcon,
} from '../../../components';
import { navRoutes } from '../../../constants';

import * as S from './style';
import { Users } from '../../../api-calls';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

const { Row, Col } = Grid;
const { Checkbox } = Inputs;

const TherapistHowItWorks = ({ to, text }) => {
  const history = useHistory();
  const { user, setUser } = useAuth();

  const [watchedTutorial, setWatchedTutorial] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (user.watchedTutorial) {
      setWatchedTutorial(true);
    }
  }, [user.watchedTutorial]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await Users.therapistHowItWorks(watchedTutorial);
    if (error) {
      setError(error.message);
    } else {
      setUser({ ...user, watchedTutorial: true });
      history.push(to);
    }
  };

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]} mb="6" ml="5">
          <TextWithIcon
            to={navRoutes.EXTERNAL.USER_GUIDE_CLIENT}
            external="true"
            underline="true"
            text="Client guide"
            icon="forwardArrow"
            weight="bold"
          />
          <TextWithIcon
            to={navRoutes.EXTERNAL.USER_GUIDE_THERAPIST}
            external="true"
            underline="true"
            text="Therapist guide"
            icon="forwardArrow"
            weight="bold"
          />
          <TextWithIcon
            to={navRoutes.EXTERNAL.USER_GUIDE_ORGANISATION}
            external="true"
            underline="true"
            text="Organisation guide"
            icon="forwardArrow"
            weight="bold"
          />
        </Col>
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            To help with this, we require all therapists to watch a video that
            will explain how to use this tool. If you like you can also arrange
            a live tutorial by emailing{' '}
            <T.Link
              to="mailto: digital@duetplatform.com"
              weight="bold"
              color="gray8"
              external
            >
              digital@duetplatform.com
            </T.Link>
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mt="6" mb="7" ml="5">
          <TextWithIcon
            to={navRoutes.EXTERNAL.HOW_IT_WORKS_VIDEO}
            external="true"
            underline="true"
            text="View video"
            icon="forwardArrow"
            weight="bold"
          />
        </Col>
        <Col w={[4, 12, 12]} mb="6">
          <Checkbox
            checked={watchedTutorial}
            label="I confirm I have watched the video"
            handleChange={setWatchedTutorial}
          />
        </Col>
      </Row>
      <S.ButtonWrapper>
        <Col w={[4, 8, 8]}>
          {error && (
            <T.P color="error" mt="3">
              {error}
            </T.P>
          )}
          <Button
            text={text}
            handleClick={handleSubmit}
            disabled={!watchedTutorial}
          />
        </Col>
      </S.ButtonWrapper>
      {!watchedTutorial && (
        <T.Link color="gray8" to={to} mt="6">
          Skip, I’ll do this later
        </T.Link>
      )}
    </>
  );
};

export default TherapistHowItWorks;
