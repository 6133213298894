import styled from '@emotion/styled';
import Image from '../Image';

export const StyledImage = styled(Image)`
  max-height: 90px;
  width: auto;

  ${({ theme }) => theme.media.mobile} {
    max-height: 70px;
  }
`;
export const Logo = styled.img`
  max-height: 90px;
  width: auto;

  ${({ theme }) => theme.media.mobile} {
    max-height: 70px;
    width: auto;
  }
`;

const CustomLogo = ({ url, image }) => {
  if (url) {
    return <Logo src={url} />;
  }

  return <StyledImage image={image || 'logo'} />;
};

export default CustomLogo;
