import { useReducer, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import validate from '../../../validation/schemas/signup';
import { navRoutes, roles } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Users } from '../../../api-calls';
import {
  ReCaptcha,
  Button,
  Inputs,
  Grid,
  Typography as T,
  Title,
} from '../../../components';
import * as S from './style';

const { BasicInput, Checkbox } = Inputs;
const { Row, Col } = Grid;

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  organisationName: '',
  agreedOnTerms: false,
  httpError: '',
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}
const cleanEmail = (email) => email.toLowerCase().trim();

const AdminSignup = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const history = useHistory();
  const { setUser } = useAuth();
  const submitAttempt = useRef(false);

  const {
    firstName,
    lastName,
    email,
    organisationName,
    password,
    agreedOnTerms,
    httpError,
    validationErrs,
    loading,
  } = state;

  const validateForm = () => {
    try {
      validate({
        role: roles.ADMIN,
        email: cleanEmail(email),
        firstName,
        lastName,
        organisationName,
        password,
        agreedOnTerms,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, password, agreedOnTerms, organisationName]);

  const handleSignup = async () => {
    setState({ loading: true });
    const { error, data } = await Users.adminSignup({
      email: cleanEmail(email),
      organisationName,
      firstName,
      lastName,
      password,
    });
    setState({ loading: false });
    if (error) {
      if (error.statusCode === 409) {
        setState({ validationErrs: { [error.data?.field]: error.message } });
      } else {
        setState({ httpError: error.message });
      }
    } else {
      setUser(data);
      history.push(navRoutes.ADMIN.CUSTOMISE);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  return (
    <S.Wrapper onSubmit={handleSubmit}>
      <Title boldSection="Sign" lightSection="Up" boldFirst />
      <Row>
        <Col w={[4, 12, 8]}>
          <T.P color="gray8" mb={6} mbM={5} style={{ maxWidth: 490 }}>
            To begin, please create account. This will create a unique link for
            you to onboard your therapists to start using the tool with clients.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mb={7} mbM={5}>
          <BasicInput
            placeholder="First name..."
            label="First name"
            value={firstName}
            handleChange={(value) => setState({ firstName: value })}
            error={validationErrs.firstName}
          />
        </Col>
        <Col w={[4, 12, 4]} mb={7} mbM={5}>
          <BasicInput
            placeholder="Last name..."
            label="Last name"
            value={lastName}
            handleChange={(value) => setState({ lastName: value })}
            error={validationErrs.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mb={7} mbM={5}>
          <BasicInput
            placeholder="Email..."
            label="Email"
            type="email"
            value={email}
            handleChange={(value) => setState({ email: value })}
            error={validationErrs.email}
          />
        </Col>
        <Col w={[4, 12, 4]} mb={7} mbM={5}>
          <BasicInput
            label="Password"
            placeholder="Password..."
            showPasswordInfo
            type="password"
            value={password}
            handleChange={(value) => setState({ password: value })}
            error={validationErrs.password}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mb={7} mbM={5}>
          <BasicInput
            placeholder="Organisation name..."
            label="Organisation"
            value={organisationName}
            handleChange={(value) => setState({ organisationName: value })}
            error={validationErrs.organisationName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]}>
          <Checkbox
            checked={agreedOnTerms}
            handleChange={(e) => setState({ agreedOnTerms: e })}
            error={validationErrs.agreedOnTerms}
            label={
              <>
                <T.P>
                  I agree to the{' '}
                  <T.Link
                    to={navRoutes.EXTERNAL.TERMS_OF_USE}
                    bold
                    underline
                    external
                  >
                    Terms of Use
                  </T.Link>{' '}
                  and{' '}
                  <T.Link
                    to={navRoutes.EXTERNAL.PRIVACY_POLICY}
                    bold
                    underline
                    external
                  >
                    Privacy Policy
                  </T.Link>
                  .
                </T.P>
              </>
            }
          />
          <T.P mt="5" color="error">
            {httpError}
          </T.P>
          <Button
            mt={7}
            mtT={5}
            mtM={5}
            text="Sign up"
            type="submit"
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]}>
          <ReCaptcha />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default AdminSignup;
