import Title from '../../../components/Title';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import * as S from './style';

const Intro = () => {
  return (
    <S.Wrapper>
      <Title boldSection="Welcome" lightSection="to Duet" mbM="3" boldFirst />
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            Duet is a space to help therapists support your client with digital
            content in between in-person sessions and enhance therapeutic
            outcomes.
          </T.P>
        </Col>
      </Row>
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            Whether you’re a therapy organisation or independent therapist,
            create accounts for each of your therapists to manage content,
            support your clients and receive updates from your clients all in
            one place.
          </T.P>
        </Col>
      </Row>
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            If you are a therapist or organisation interested in using the tool
            please contact{' '}
            <T.Link
              to="mailto: digital@duetplatform.com"
              weight="bold"
              color="gray8"
            >
              digital@duetplatform.com
            </T.Link>
          </T.P>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Intro;
