import { fields, createSchema, validate as _validate } from '..';

const { phoneUpdates, optionalCheckbox } = fields;

const welcomeData = createSchema({
  contactNumber: phoneUpdates,
  dontContactByPhone: optionalCheckbox,
});

const validate = (data) => _validate(welcomeData, data);

export default validate;
