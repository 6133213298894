import { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { validateNewsLetter } from '../../../validation/schemas/songForLife';

import { decode } from 'html-entities';

import {
  Button,
  Inputs as I,
  Grid,
  Typography as T,
  Image,
} from '../../../components';
import * as S from './style';

const NewsletterForm = ({
  status,
  message,
  onValidated,
  setIsModalVisible,
}) => {
  const [submitError, setSubmitError] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { Row, Col } = Grid;

  const validateForm = () => {
    try {
      validateNewsLetter({
        email,
        firstName,
        lastName,
      });
      setValidationErrors({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleFormSubmit = async () => {
    const isValid = validateForm();

    if (isValid) {
      onValidated({
        EMAIL: email,
        NAME: firstName,
        LNAME: lastName,
      });

      setSubmitError(null);
      setValidationErrors(null);
      setTimeout(() => {
        setIsModalVisible(false);
      }, 2000);

      setEmail('');
      setFirstName('');
      setLastName('');
    }
    return false;
  };

  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      handleFormSubmit();
    }
  };

  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split('-') ?? null;
    if ('0' !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  return (
    <S.NewsLetterWrapper>
      <Image image="songForLifeNewsLetter" />
      <T.H3 weight="bold" mt="3">
        Sign up to receive tips and reminders about your Song for Life Card!
      </T.H3>
      <Row mt="3">
        <Col w={[4, 12, 12]}>
          <I.BasicInput
            name="email"
            placeholder="Email..."
            label="Email"
            value={email}
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            onKeyUp={(event) => handleInputKeyEvent(event)}
            error={validationErrors?.validationErrs?.email}
          />
        </Col>
        <Col w={[4, 12, 12]} mt={3}>
          <I.BasicInput
            name="firstName"
            placeholder="First name..."
            label="First name"
            value={firstName}
            onChange={(event) => setFirstName(event?.target?.value ?? '')}
            onKeyUp={(event) => handleInputKeyEvent(event)}
            error={validationErrors?.validationErrs?.firstName}
          />
        </Col>
        <Col w={[4, 12, 12]} mt={3}>
          <I.BasicInput
            name="lastName"
            placeholder="Last name..."
            label="Last name"
            value={lastName}
            onChange={(event) => setLastName(event?.target?.value ?? '')}
            onKeyUp={(event) => handleInputKeyEvent(event)}
            error={validationErrors?.validationErrs?.lastName}
          />
        </Col>
      </Row>

      <Row>
        <Col w={[4, 12, 12]}>
          <Button
            mt={6}
            mtT={5}
            mtM={5}
            text="Subscribe"
            customColor="gradientBlue"
            type="submit"
            loading={status === 'sending'}
            onClick={handleFormSubmit}
            style={{ width: '230px' }}
            disabled={
              status === 'sending' || status === 'success' || status === 'error'
            }
          />
        </Col>
      </Row>
      <Row mt="3">
        <Col w={[4, 12, 12]}>
          <T.P>
            We will only email you about the Song for Life card and you can
            unsubscribe at any time.
          </T.P>
        </Col>
      </Row>
      <Row mt={4}>
        {status === 'sending' && <div>Sending...</div>}
        {status === 'error' || submitError ? (
          <div
            className="newsletter-form-error"
            dangerouslySetInnerHTML={{
              __html: submitError || getMessage(message),
            }}
          />
        ) : null}
        {status === 'success' && status !== 'error' && !submitError && (
          <div dangerouslySetInnerHTML={{ __html: decode(message) }} />
        )}
      </Row>
    </S.NewsLetterWrapper>
  );
};

const NewsletterSubscribe = ({ setIsModalVisible }) => {
  const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_SONG_FOR_LIVE_URL;

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            setIsModalVisible={setIsModalVisible}
          />
        );
      }}
    />
  );
};

export default NewsletterSubscribe;
