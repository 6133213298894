import { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Global } from '@emotion/react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route as RouterRoute,
} from 'react-router-dom';
import theme, { globalStyle } from './theme';
import { Route } from './components';
import * as Pages from './pages';
import { navRoutes, roles } from './constants';
import { AuthProvider } from './context/auth';
import { ScrollToTop } from './helpers';
import CookieBot from 'react-cookiebot';
import { OrgProvider } from './context/organisation';
import GoogleAnalytics from './GoogleAnalytics';

import 'antd/dist/antd.css';

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
const isProduction = process.env.NODE_ENV === 'production';

function App({ ReactGA }) {
  useEffect(
    () =>
      window?.localStorage?.getItem('isLarge') === 'true'
        ? (document.getElementsByTagName('html')[0].style.fontSize = '19px')
        : (document.getElementsByTagName('html')[0].style.fontSize = '16px'),
    []
  );

  return (
    <div className="app">
      <Global styles={globalStyle} />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <GoogleAnalytics isProduction={isProduction} ReactGA={ReactGA} />
            <ScrollToTop />
            <OrgProvider>
              <Switch>
                <RouterRoute exact path={navRoutes.GENERAL.HOME}>
                  <Redirect to={navRoutes.GENERAL.LOGIN} />
                </RouterRoute>

                {/* ALL ADMIN PAGES */}
                <RouterRoute path={navRoutes.ADMIN.BASE}>
                  <Pages.ADMIN />
                </RouterRoute>

                {/* Auth Routes */}
                <Route
                  exact
                  path={navRoutes.GENERAL.LOGIN}
                  Component={Pages.GENERAL.Login}
                  layout="onboarding"
                  loggedOutOnly
                />
                <Route
                  exact
                  path={navRoutes.GENERAL.ORGANISATION_LOGIN}
                  Component={Pages.GENERAL.OrganisationLogin}
                  layout="onboarding"
                  loggedOutOnly
                />
                <Route
                  exact
                  path={navRoutes.GENERAL.SONG_FOR_LIFE}
                  Component={Pages.GENERAL.SongForLife}
                  layout="public"
                  image="blueBackground"
                />
                <Route
                  exact
                  path={navRoutes.GENERAL.ACCESSIBILITY}
                  Component={Pages.GENERAL.AccessibilityPage}
                  layout="accessibility"
                  image="blueBackground"
                />

                <Route
                  exact
                  path={navRoutes.ADMIN.LOGIN}
                  Component={Pages.GENERAL.Login}
                  layout="onboarding"
                  loggedOutOnly
                  adminLogin
                />
                <Route
                  exact
                  path={navRoutes.THERAPIST.SIGNUP}
                  Component={Pages.THERAPIST.Signup}
                  layout="onboarding"
                  loggedOutOnly
                />

                <Route
                  path={navRoutes.CLIENT.SIGNUP}
                  Component={Pages.CLIENT.Signup}
                  layout="onboarding"
                  loggedOutOnly
                />

                <Route
                  exact
                  path={navRoutes.GENERAL.FORGOT_PASSWORD}
                  Component={Pages.GENERAL.ForgotPassword}
                  layout="onboarding"
                  loggedOutOnly
                />

                <Route
                  exact
                  path={navRoutes.GENERAL.RESET_PASSWORD}
                  Component={Pages.GENERAL.ResetPassword}
                  layout="onboarding"
                  loggedOutOnly
                />

                {/* Therapist Pages */}

                <Route
                  exact
                  path={navRoutes.THERAPIST.WELCOME}
                  Component={Pages.GENERAL.Welcome}
                  layout="info"
                  section="welcome"
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.HOW_IT_WORKS}
                  Component={Pages.THERAPIST.HowItWorks}
                  layout="info"
                  section="welcome"
                  isPrivate
                  image="momPink"
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.CLIENT}
                  Component={Pages.THERAPIST.SingleClient}
                  layout="general"
                  goBack
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.FEEDBACK}
                  Component={Pages.THERAPIST.Feedback}
                  layout="general"
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />
                <Route
                  exact
                  path={navRoutes.THERAPIST.CLIENT_FEEDBACK}
                  Component={Pages.THERAPIST.ClientFeedback}
                  layout="onboarding"
                  goBack
                  isPrivate
                  allowedRoles={[
                    roles.THERAPIST,
                    roles.ADMIN,
                    roles.SUPER_ADMIN,
                  ]}
                />
                <Route
                  exact
                  path={navRoutes.THERAPIST.CLIENT_HISTORY}
                  Component={Pages.THERAPIST.SingleClient}
                  layout="general"
                  goBack
                  clientHistory
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.EDIT_CLIENT}
                  Component={Pages.THERAPIST.EditClient}
                  layout="general"
                  goBack
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.DASHBOARD}
                  Component={Pages.THERAPIST.Dashboard}
                  layout="general"
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.NEW_CLIENT}
                  Component={Pages.THERAPIST.AddClient}
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.SINGLE_PROGRAMME}
                  Component={Pages.THERAPIST.IndividProgramme}
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.PROFILE}
                  Component={Pages.THERAPIST.Profile}
                  layout="onboarding"
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.LIBRARY}
                  Component={Pages.THERAPIST.Library}
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.ACCOUNT}
                  Component={Pages.THERAPIST.MyAccount}
                  goBack
                  maxWidth="none"
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.CONTACT_CLIENT}
                  Component={Pages.THERAPIST.ContactClient}
                  goBack
                  maxWidth="1200px"
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />

                <Route
                  exact
                  path={navRoutes.THERAPIST.PROGRESS_UPDATE}
                  Component={Pages.THERAPIST.ViewUpdate}
                  goBack
                  isPrivate
                  allowedRoles={[roles.THERAPIST]}
                />
                <Route
                  exact
                  path={navRoutes.THERAPIST.SUCCESS_UPDATE}
                  Component={Pages.GENERAL.SuccessUpdate}
                  goBack
                  maxWidth="none"
                />
                <Route
                  exact
                  path={navRoutes.GENERAL.DECIDE_ROLE}
                  Component={Pages.GENERAL.DecideRole}
                />

                {/* Client Pages */}

                <Route
                  exact
                  path={navRoutes.CLIENT.WELCOME}
                  Component={Pages.GENERAL.Welcome}
                  layout="info"
                  section="welcome"
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.DASHBOARD}
                  Component={Pages.CLIENT.Dashboard}
                  layout="general"
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.DELETE_ACCOUNT}
                  Component={Pages.CLIENT.DeleteAccount}
                  layout="general"
                  allowedRoles={[roles.CLIENT]}
                  isPrivate
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.THERAPIST}
                  Component={Pages.CLIENT.MyTherapist}
                  layout="general"
                  image="hands"
                  goBack
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.CONTACT_THERAPIST}
                  Component={Pages.CLIENT.MyTherapist}
                  layout="general"
                  image="hands"
                  goBack
                  contactDetails
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.PROGRAMMES}
                  Component={Pages.CLIENT.AllProgrammes}
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.THERAPY_PLAN}
                  Component={Pages.CLIENT.MyTherapyPlan}
                  goBack
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.THERAPY_GOALS}
                  Component={Pages.CLIENT.MyTherapyGoals}
                  goBack
                  maxWidth="none"
                  isPrivate
                  allowedRoles={[roles.CLIENT]}
                />
                <Route
                  exact
                  isPrivate
                  path={navRoutes.CLIENT.ACCOUNT}
                  Component={Pages.CLIENT.MyAccount}
                  goBack
                  maxWidth="none"
                  allowedRoles={[roles.CLIENT]}
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.SEND_UPDATE}
                  Component={Pages.CLIENT.Update}
                  goBack
                  maxWidth="none"
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.SUCCESS_UPDATE}
                  Component={Pages.GENERAL.SuccessUpdate}
                  goBack
                  maxWidth="none"
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.SUCCESS_FEEDBACK}
                  Component={Pages.GENERAL.SuccessFeedback}
                  goBack
                  maxWidth="none"
                />

                <Route
                  exact
                  path={navRoutes.CLIENT.SEND_FEEDBACK}
                  Component={Pages.CLIENT.Feedback}
                  maxWidth="none"
                />

                {/* keep this after all /programmes routes, because it has an optional parameter */}
                <Route
                  exact
                  path={navRoutes.CLIENT.PROTECTED_PROGRAMME}
                  Component={Pages.CLIENT.IndividProgramme}
                />

                {/* therapist sub routes */}

                <Route
                  path={navRoutes.THERAPIST.SHARE_PROGRAMME}
                  Component={Pages.THERAPIST.ShareProgramme}
                  layout="general"
                  allowedRoles={[roles.THERAPIST]}
                  isPrivate
                />

                <Route
                  path={navRoutes.THERAPIST.CREATE_PROGRAMME}
                  Component={Pages.THERAPIST.CreateProgram}
                  layout="general"
                  allowedRoles={[roles.THERAPIST]}
                  isPrivate
                />

                <Route
                  path={navRoutes.THERAPIST.EDIT_PROGRAMME}
                  Component={Pages.THERAPIST.EditProgramme}
                  layout="general"
                  allowedRoles={[roles.THERAPIST]}
                />

                {/* General routes */}

                <Route
                  exact
                  path={navRoutes.GENERAL.HOW_TO_RECORD}
                  Component={Pages.GENERAL.HowToRecord}
                  layout="general"
                />

                <Route
                  exact
                  path={navRoutes.GENERAL.ACCOUNT_DELETED_SUCCESS}
                  Component={Pages.GENERAL.AccountDeletedSuccess}
                  layout="onboarding"
                  loggedOutOnly
                />

                <Route
                  exact
                  path={navRoutes.GENERAL.ORGANISATION_HOME}
                  Component={Pages.GENERAL.OrganisationLogin}
                  layout="onboarding"
                  loggedOutOnly
                />

                <Route
                  Component={Pages.GENERAL.ErrorPages}
                  layout="general"
                  goBack
                  status="404"
                  title="Page not found"
                  msg="Sorry, the page you're looking for doesn't seem to exist"
                />
              </Switch>
            </OrgProvider>
          </Router>
        </AuthProvider>
        <CookieBot domainGroupId={domainGroupId} />
      </ThemeProvider>
    </div>
  );
}

export default App;
