/*
Item                   Color Style
__________________________________
Input headers               gray-9
__________________________________
Paragraph text              gray-8
__________________________________
Placeholder                 gray-7
__________________________________
Disable                     gray-6
__________________________________
Border                      gray-5
__________________________________
View programme cards,       gray-3
Gray circles in View
 therapy cards etc
__________________________________
Input background            gray-2
*/

import gradients from './gradients';

const purple = '#951B81';
const blue = '#6F739C';
const pink = '#E50070';
const green = '#009C54';

export default {
  primary: purple,
  secondary: pink,
  tertiary: green,
  white: '#ffffff',
  gray1: '#F3F1F3',
  gray2: '#F9FAFB',
  gray3: '#EDF0F3',
  gray4: '#E0E3E6',
  gray5: '#D0D4D9',
  gray6: '#AAAFB6',
  gray7: '#6E7881',
  gray8: '#5E6875',
  gray9: '#3C404B',
  gray10: '#1A202B',
  black: '#0E0F11',
  neutral90: '#4B444A',
  pink,
  blue,
  purple,
  error: pink,
  green: green,
  text: '#1A202B',
  gradientBlue: gradients.blue,
};
