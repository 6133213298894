import { useState, useEffect } from 'react';

import { Organisations } from '../../../api-calls';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import Title from '../../../components/Title';
import UserRow from './userRow';

function AllOrganisations() {
  const [organisations, setOrganisations] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState([]);

  useEffect(() => {
    const getOrganisations = async () => {
      const data = await Organisations.getOrganisations();
      setOrganisations(data);
    };
    getOrganisations();
  }, []);

  return (
    <div>
      <Title mb="7" lightSection="All" boldSection="Organisations" />
      <Row mb="7">
        <Col w={[4, 7, 7]}>
          <T.P>
            On this page you can view all organisations, deactivate and approve
            organisations.
          </T.P>
        </Col>
      </Row>
      <Row mb="6">
        <Col w={[4, 2, 2]}>
          <T.P color="gray9" weight="700">
            Organisation
          </T.P>
        </Col>
        <Col w={[4, 2, 2]}>
          <T.P color="gray9" weight="700">
            Email
          </T.P>
        </Col>
        <Col w={[4, 4, 4]}>
          <T.P color="gray9">Action</T.P>
        </Col>
      </Row>
      {organisations
        ? organisations.map((organisation) => (
            <UserRow
              key={organisation.id}
              organisation={organisation}
              setOrganisations={setOrganisations}
              setError={setError}
            />
          ))
        : 'error'}
      {/* <S.ButtonWrapper>
        <Col w={[4, 3, 3]}>
          {error && <T.P color="error">{error}</T.P>}
          <Button to={navRoutes.ADMIN.DASHBOARD} text="Export change log" />
        </Col>
      </S.ButtonWrapper> */}
    </div>
  );
}

export default AllOrganisations;
