export const therapyGoalsCategories = [
  {
    value: 'Communication',
    label: 'Communication',
  },
  {
    value: 'Emotional wellbeing',
    label: 'Emotional wellbeing',
  },
  {
    value: 'Cognition and learning',
    label: 'Cognition and learning',
  },
  {
    value: 'Physical skills',
    label: 'Physical skills',
  },
];
