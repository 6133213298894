import { useState, useRef, useEffect } from 'react';
import { Grid, Inputs, Button, Typography as T } from '../../../components';
import * as S from './style';
import validate from '../../../validation/schemas/welcome-client';
import { Users } from '../../../api-calls';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

const { Row, Col } = Grid;
const { BasicInput, Checkbox } = Inputs;

const AddPhoneNumber = ({ to, text }) => {
  const { user, setUser } = useAuth();
  const [contactNumber, setContactNumber] = useState(user.contactNumber);
  const [dontContactByPhone, setDontContactByPhone] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const submitAttempt = useRef(false);
  const history = useHistory();

  useEffect(() => {
    setContactNumber(user.contactNumber?.trim());
  }, [user.contactNumber]);

  const validateData = () => {
    try {
      validate({
        contactNumber,
        dontContactByPhone,
      });
      setErrors({});
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors(error.inner);
      }
      return false;
    }
  };

  const updateData = async () => {
    setLoading(true);
    const { error } = await Users.updatePhoneNumber({
      contactNumber,
      contactByPhone: !dontContactByPhone,
    });
    if (error) {
      setErrors({ server: error.message });
    } else {
      setUser({
        ...user,
        contactNumber,
        contactByPhone: !dontContactByPhone,
      });
      history.push(to);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateData();
    if (isValid) {
      updateData();
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactNumber, dontContactByPhone]);
  return (
    <S.ButtonWrapper>
      <Row>
        <Col w={[4, 8, 8]} mt="6">
          <BasicInput
            placeholder="Phone number..."
            label="Phone number"
            value={contactNumber}
            handleChange={setContactNumber}
            error={errors?.contactNumber}
          />
        </Col>
        <Col w={[4, 8, 8]} mt="6" mb="6">
          <Checkbox
            checked={dontContactByPhone}
            label="Do not contact me by phone"
            handleChange={setDontContactByPhone}
            error={errors?.dontContactByPhone}
          />
        </Col>
      </Row>
      <Col w={[4, 8, 8]}>
        {errors?.server && <T.P>{errors.server}</T.P>}
        <Button handleClick={handleSubmit} text={text} loading={loading} />
      </Col>
    </S.ButtonWrapper>
  );
};

export default AddPhoneNumber;
