import { useEffect, createContext, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Organisations } from '../api-calls';
import { useAuth } from './auth';
import { organisationsStatuses, navRoutes, userRoles } from '../constants';

const initialUserState = {
  id: null,
  name: '',
  logoUrl: '',
  uniqueSlug: '',
  status: '',
  visitedSinceApproval: '',
};

const OrgContext = createContext({
  org: initialUserState,
  getOrgInfo: () => {},
  setOrg: () => {},
});

const OrgProvider = (props) => {
  const [org, setOrg] = useState(initialUserState);
  const { user } = useAuth();
  const history = useHistory();

  const _setOrg = (data) => {
    // set org in state
    setOrg(data);
  };

  const getOrgInfo = async (id) => {
    const { data } = await Organisations.getOrganisationById({
      id: id || user.organisationId,
    });

    if (data) {
      _setOrg(data);
      if (
        data.status === organisationsStatuses.ACTIVE &&
        !data.visitedSinceApproval &&
        user.role === userRoles.ADMIN
      ) {
        history.push(navRoutes.ADMIN.APPROVE_CREATE_ACCOUNT);
      }
    } else {
      _setOrg(initialUserState);
    }
  };

  useEffect(() => {
    if (user.organisationId && user.role !== userRoles.SUPER_ADMIN) {
      getOrgInfo();
      return () => _setOrg(initialUserState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.organisationId]);

  const value = {
    org,
    getOrgInfo,
    setOrg: _setOrg,
  };

  return <OrgContext.Provider value={value} {...props} />;
};

const useOrg = () => {
  const value = useContext(OrgContext);
  return value;
};

export { OrgProvider, useOrg };
export default OrgContext;
