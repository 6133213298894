import { Row, Col } from '../Grid';
import * as T from '../Typography';
import * as S from './style';

const SubHeader = ({ children }) => {
  return (
    <Row mb="7">
      <Col w={[4, 12, 6]} dir="column" ai="flex-start">
        <S.GreenLine />
        <T.H5 color="gray7">{children}</T.H5>
      </Col>
    </Row>
  );
};

export default SubHeader;
