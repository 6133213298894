// NOTE!!! IF YOU CHANGE ANY TYPES HERE MAKE SURE YOU UPDATE THE SERVER CONSTANTS FILE AS WELL

export const userRoles = {
  CLIENT: 'CLIENT',
  THERAPIST: 'THERAPIST',
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const userStatuses = {
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  DEACTIVATED: 'DEACTIVATED',
};

export const mediaTypes = {
  PROFILE_IMAGE: 'PROFILE_IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  IMAGE: 'IMAGE',
};
export const programmeStatuses = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED',
};

export const organisationsStatuses = {
  ACTIVE: 'ACTIVE',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  DEACTIVATED: 'DEACTIVATED',
};
