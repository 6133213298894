import { Title } from '../../../components';

import ClientsSection from '../../../components/ClientsSection';
import { userRoles } from '../../../constants';
import { useAuth } from '../../../context/auth';

const Feedback = () => {
  const { user } = useAuth();

  return (
    <div style={{ maxWidth: 1068 }}>
      <Title
        lightSection={user.role === userRoles.ADMIN ? 'My' : 'All'}
        boldSection="Feedback"
      />
      <ClientsSection toFeedback />
    </div>
  );
};

export default Feedback;
