const DownArrow = ({ width, height, color, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 12C2.25 6.61547 6.61547 2.25 12 2.25C17.3845 2.25 21.75 6.61547 21.75 12C21.75 17.3845 17.3845 21.75 12 21.75C6.61547 21.75 2.25 17.3845 2.25 12ZM6.96984 10.6552L11.4698 15.1552C11.6105 15.2957 11.8012 15.3747 12 15.3747C12.1988 15.3747 12.3895 15.2957 12.5302 15.1552L17.0302 10.6552C17.1649 10.5134 17.2389 10.3245 17.2364 10.129C17.2339 9.9334 17.1551 9.74655 17.0168 9.60825C16.8785 9.46995 16.6916 9.39114 16.496 9.38864C16.3005 9.38614 16.1116 9.46013 15.9698 9.59484L12 13.5642L8.03016 9.59484C7.88836 9.46013 7.69955 9.38614 7.50397 9.38864C7.3084 9.39114 7.12155 9.46995 6.98325 9.60825C6.84495 9.74655 6.76614 9.9334 6.76364 10.129C6.76113 10.3245 6.83513 10.5134 6.96984 10.6552Z"
      fill={color || 'white'}
    />
  </svg>
);

export default DownArrow;
