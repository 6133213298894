import { fields, createSchema, validate as _validate } from '..';

const { requiredText, email } = fields;

const songForLife = createSchema({
  artist: requiredText,
  trackTitle: requiredText,
  email,
});

const songForLifeNewsLetter = createSchema({
  firstName: requiredText,
  lastName: requiredText,
  email,
});

const validate = (data) => _validate(songForLife, data);

export const validateNewsLetter = (data) =>
  _validate(songForLifeNewsLetter, data);

export default validate;
