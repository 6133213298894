const User = ({ width, height, color, ...props }) => (
  <svg
    width={width || '68'}
    height={height || '70'}
    viewBox="0 0 68 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.4524 57.1133C62.7944 53.1861 60.3884 49.6189 57.3684 46.6103C54.3575 43.5931 50.7909 41.1875 46.8655 39.5264C46.8303 39.5088 46.7951 39.5 46.76 39.4824C52.2356 35.5273 55.7951 29.085 55.7951 21.8164C55.7951 9.77539 46.0393 0.0195312 33.9983 0.0195312C21.9573 0.0195312 12.2014 9.77539 12.2014 21.8164C12.2014 29.085 15.761 35.5273 21.2365 39.4912C21.2014 39.5088 21.1662 39.5176 21.1311 39.5352C17.1936 41.1963 13.6604 43.5781 10.6281 46.6191C7.61095 49.63 5.20534 53.1966 3.54417 57.1221C1.91223 60.9651 1.03209 65.0854 0.951392 69.2598C0.949046 69.3536 0.965499 69.4469 0.999783 69.5342C1.03407 69.6216 1.08549 69.7012 1.15101 69.7684C1.21654 69.8355 1.29485 69.8889 1.38132 69.9253C1.46779 69.9617 1.56068 69.9805 1.65452 69.9805H6.92795C7.31467 69.9805 7.62229 69.6729 7.63108 69.2949C7.80686 62.5098 10.5315 56.1553 15.3479 51.3389C20.3313 46.3555 26.9494 43.6133 33.9983 43.6133C41.0471 43.6133 47.6653 46.3555 52.6487 51.3389C57.4651 56.1553 60.1897 62.5098 60.3655 69.2949C60.3742 69.6816 60.6819 69.9805 61.0686 69.9805H66.342C66.4359 69.9805 66.5287 69.9617 66.6152 69.9253C66.7017 69.8889 66.78 69.8355 66.8455 69.7684C66.911 69.7012 66.9625 69.6216 66.9967 69.5342C67.031 69.4469 67.0475 69.3536 67.0451 69.2598C66.9573 65.0586 66.0871 60.9717 64.4524 57.1133ZM33.9983 36.9336C29.9641 36.9336 26.1672 35.3604 23.3108 32.5039C20.4543 29.6475 18.8811 25.8506 18.8811 21.8164C18.8811 17.7822 20.4543 13.9854 23.3108 11.1289C26.1672 8.27246 29.9641 6.69922 33.9983 6.69922C38.0324 6.69922 41.8293 8.27246 44.6858 11.1289C47.5422 13.9854 49.1155 17.7822 49.1155 21.8164C49.1155 25.8506 47.5422 29.6475 44.6858 32.5039C41.8293 35.3604 38.0324 36.9336 33.9983 36.9336Z"
      fill={color || 'white'}
    />
  </svg>
);

export default User;
