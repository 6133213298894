import { useReducer, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

const initialState = {
  artist: '',
  trackTitle: '',
  email: '',
  httpError: '',
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const SongForLife = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const [step, setStep] = useState(1);

  const { artist, trackTitle, email, httpError, validationErrs, loading } =
    state;

  return step === 1 ? (
    <Step1
      artist={artist}
      trackTitle={trackTitle}
      email={email}
      validationErrs={validationErrs}
      httpError={httpError}
      loading={loading}
      setState={setState}
      setStep={setStep}
    />
  ) : (
    <Step2 artist={artist} trackTitle={trackTitle} email={email} />
  );
};

export default SongForLife;
