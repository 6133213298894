import * as S from './style';
import { Grid, Typography as T, Title, Line } from '../../../components';
import { EXTERNAL } from '../../../constants/nav-routes';
const { Row, Col } = Grid;

const AccessibilityPage = () => {
  return (
    <S.Wrapper>
      <Title
        boldSection="Accessibility"
        lightSection="Statement "
        boldFirst
        w={[4, 12, 12]}
      />
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Accessibility on this website is guided by government standards and
            the Web Content Accessibility Guidelines WCAG are widely accepted as
            the international standard for accessibility on the web.
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Whilst we aim to make this website accessible to all users and
            achieve a conformance level ‘AAA’; we continually work with
            stakeholders to ensure that conformance level ‘A’ is adhered to as a
            minimum.
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            If you experience any accessibility issue on this site or have any
            comment, please{' '}
            <T.Link
              to={EXTERNAL.CONTACT_US}
              external
              underline
              color="blue"
              weight="bold"
            >
              contact us
            </T.Link>
            .
          </T.P>
        </Col>

        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <Line />
        </Col>

        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.H3 color="black" weight="bold">
            Adjusting Text Size
          </T.H3>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Internet Explorer
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Go to “View” on the menu bar &gt; Select text size / zoom
          </T.P>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Firefox
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Go to “View” on the menu bar &gt; Select text size / zoom.
            Alternatively hold down the “Ctrl” button on your keyboard and press
            the plus (+) key to increase text size. To reduce the latter hold
            down the “Ctrl” button and press the minus (-) key.
          </T.P>
        </Col>

        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Please note that the above settings may differ depending on the
            browser version.
          </T.P>
        </Col>

        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <Line />
        </Col>

        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.H3 color="black" weight="bold">
            Text To Speech
          </T.H3>
        </Col>

        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Many computers and mobile devices today have built in text-to-speech
            software. Here are guides for each of the major browsers and
            devices:
          </T.P>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Chrome
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            <T.Link
              external
              underline
              color="blue"
              to={EXTERNAL.CHROME_GOOGLE_SPEAK}
            >
              Click here to download Google Speak
            </T.Link>{' '}
            and select the Add to Chrome button
          </T.P>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Windows Edge
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            Open the Edge browser and then click on Read Aloud Option or on your
            keyboard press Ctrl + Shift + U
          </T.P>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Android Apps
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            <T.Link
              external
              underline
              color="blue"
              to={EXTERNAL.VOICE_ALOUD_READER_ANDROID}
            >
              Click here to download Read Aloud
            </T.Link>{' '}
            for Android phones or tablets
          </T.P>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Apple
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mb="6" mbM="4">
          <T.P color="gray8">
            <T.Link
              external
              underline
              color="blue"
              to={EXTERNAL.VOICE_ALOUD_READER_APPLE}
            >
              Click here to download Voice Aloud Reader
            </T.Link>{' '}
            for Apple devices. Choose your device when you download. Or go to
            your Apple App store and search Voice Aloud Reader
          </T.P>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default AccessibilityPage;
