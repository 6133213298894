import React from 'react';
import PropTypes from 'prop-types';

import Onboarding from './Onboarding';
import Info from './Info';
import General from './General';
import Public from './Public';
import theme from '../../theme';
import { useMediaQuery } from 'react-responsive';
const Layout = ({ layout, ...props }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });
  switch (layout) {
    case 'public':
      return <Public {...props} />;
    case 'onboarding':
      return <Onboarding {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'accessibility':
      return !isMobile ? <Info {...props} /> : <General {...props} />;
    case 'general':
    default:
      return <General {...props} />;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
