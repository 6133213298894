import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';
import AccessibilityBar from '../../AccessibilityBar';
import { Link } from 'react-router-dom';
import theme from '../../../theme';
import CustomLogo from '../../../components/CustomLogo';

import { useMediaQuery } from 'react-responsive';

const Public = ({ children, goBack, maxWidth, ...props }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });
  return (
    <S.Container>
      <S.Wrapper>
        {!isMobile && <AccessibilityBar />}

        <S.OnboardingHeader>
          <Link to="/" aria-label="Home Page" style={{ maxWidth: '120px' }}>
            <CustomLogo image="logo" />
          </Link>
        </S.OnboardingHeader>
      </S.Wrapper>
      <S.Content maxWidth={maxWidth}>{children}</S.Content>
    </S.Container>
  );
};

Public.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Public;
