import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  ClientRoutes,
  TherapistRoutes,
  AdminRoutes,
  SuperAdminRoutes,
} from './NavRoutes';
import { useAuth } from '../../context/auth';
import R from '../../constants/roles';
import theme from '../../theme';
import Button from '../Button';
import * as S from './style';
import Icon from '../Icon';
import Image from '../Image';
import * as T from '../Typography';
import { navRoutes } from '../../constants';

const decideRoutes = (role) => {
  if (role === R.ADMIN) {
    return AdminRoutes;
  } else if (role === R.THERAPIST) {
    return TherapistRoutes;
  } else if (role === R.CLIENT) {
    return ClientRoutes;
  } else if (role === R.SUPER_ADMIN) {
    return SuperAdminRoutes;
  }
  return null;
};

const NavItems = ({ setOpen }) => {
  const { user } = useAuth();

  const NavRoutes = decideRoutes(user.role);
  if (!NavRoutes) return null;
  return (
    <S.Div>
      <NavRoutes setOpen={setOpen} />
    </S.Div>
  );
};
const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });
  const [isLarge, setIsLarge] = useState(() => {
    return window?.localStorage?.getItem('isLarge') || false;
  });
  return !isTablet ? (
    <>
      <NavItems />
    </>
  ) : (
    <>
      <Icon icon="menu" width={20} height={20} onClick={() => setOpen(true)} />
      <S.Drawer
        title="Basic Drawer"
        placement={'right'}
        closable={false}
        visible={open}
        key={'placement'}
        maskStyle={{ display: 'none' }}
        headerStyle={{ display: 'none' }}
        width="100%"
        drawerStyle={{ background: 'white', display: 'flex' }}
      >
        <Icon
          icon="cross"
          width={18}
          height={18}
          onClick={() => setOpen(false)}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            zIndex: 2,
          }}
        />
        <S.Div>
          <S.Collapse
            bordered={false}
            expandIconPosition="right"
            onChange={() => setOpenCollapse(!openCollapse)}
            expandIcon={({ isActive }) =>
              isActive ? (
                <Icon icon="upArrow" color="white" />
              ) : (
                <Icon icon="downArrow" color="white" />
              )
            }
          >
            <S.InnerDiv />
            <S.AntPanel
              header={
                <T.P weight="bold" color="white">
                  View accessibility options{' '}
                </T.P>
              }
              key="1"
            >
              <S.CollapseLink
                to={navRoutes.GENERAL.ACCESSIBILITY}
                onClick={() => setOpen(false)}
              >
                <Icon
                  icon="accessibility"
                  color="white"
                  text="Site accessibility"
                />
              </S.CollapseLink>
              <Button
                handleClick={() => {
                  if (!isLarge) {
                    document.getElementsByTagName('html')[0].style.fontSize =
                      '19px';
                    window?.localStorage?.setItem('isLarge', 'true');
                    setIsLarge(true);
                  } else {
                    document.getElementsByTagName('html')[0].style.fontSize =
                      '16px';
                    window?.localStorage?.removeItem('isLarge');
                    setIsLarge(false);
                  }
                  setOpen(false);
                }}
                text={
                  !isLarge ? 'A+ Increase text size' : 'A- Decrease text size'
                }
                w="160"
                customColor="gray9"
              ></Button>
            </S.AntPanel>
          </S.Collapse>
        </S.Div>
        {!openCollapse && <NavItems setOpen={setOpen} />}

        <S.RainbowContainer>
          <Image
            image="rainbow"
            customStyle={{ width: '100%', opacity: '0.5' }}
          />
        </S.RainbowContainer>
      </S.Drawer>
    </>
  );
};

export default Navbar;
