import Title from '../../../components/Title';
import Button from '../../../components/Button';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import * as S from './style';

import { navRoutes } from '../../../constants';

const Success = () => {
  return (
    <S.Wrapper>
      <Title boldSection="Success!" mbM="3" boldFirst />
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            You’ve successfully created your account. Remember that you can edit
            the details at any time.
          </T.P>
        </Col>
      </Row>
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8" weight="bold">
            Our team are now reviewing your account and will notify you by email
            within 24 hours.
          </T.P>
        </Col>
      </Row>

      <S.ButtonWrapper>
        <Col w={[4, 8, 8]}>
          <Button to={navRoutes.ADMIN.DASHBOARD} text="Home" />
        </Col>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default Success;
