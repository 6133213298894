import { useState } from 'react';
import * as S from './style';
import Icon from '../Icon';
import Button from '../Button';
import { navRoutes } from '../../constants';
const AccessibilityBar = () => {
  const [isLarge, setIsLarge] = useState(() => {
    return window?.localStorage?.getItem('isLarge') || false;
  });
  return (
    <S.Div1>
      <S.Div>
        <S.Link to={navRoutes.GENERAL.ACCESSIBILITY}>
          <Icon icon="accessibility" color="white" text="Site accessibility" />
        </S.Link>
        <Button
          handleClick={() => {
            if (!isLarge) {
              document.getElementsByTagName('html')[0].style.fontSize = '19px';
              window?.localStorage?.setItem('isLarge', 'true');
              setIsLarge(true);
            } else {
              document.getElementsByTagName('html')[0].style.fontSize = '16px';
              window?.localStorage?.removeItem('isLarge');
              setIsLarge(false);
            }
          }}
          text={!isLarge ? 'A+ Increase text size' : 'A- Decrease text size'}
          w="160"
          customColor="gray9"
        ></Button>
      </S.Div>
    </S.Div1>
  );
};

export default AccessibilityBar;
