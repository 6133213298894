import { useEffect, useState } from 'react';

import Title from '../../../components/Title';
import { BasicInput } from '../../../components/Inputs';
import FileUpload from '../../../components/FileUpload';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/Button';
import { Row, Col } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import * as T from '../../../components/Typography';

import { useAuth } from '../../../context/auth';
import { useOrg } from '../../../context/organisation';

import validate from '../../../validation/schemas/account';

import { roles, content, navRoutes } from './../../../constants';

import { Users, Media } from '../../../api-calls';

const initState = {
  firstName: '',
  lastName: '',
  email: '',
  logoMediaId: '',
  // ORGANISATION
  organisationId: null,
  organisationName: '',
  uniqueSlug: '',
  // file upload
  uploadedFileInfo: {
    id: null,
    name: '',
    key: '',
    bucketRegion: '',
    bucket: '',
    fileType: '',
    isNew: false,
    uploadedToS3: false,
    size: 0,
  },
};

const MyAccount = () => {
  const [accountDetails, setAccountDetails] = useState(initState);
  const [errors, setErrors] = useState({});
  const [updateAttempt, setUpdateAttempt] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // file upload
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);

  const { user, setUser } = useAuth();
  const { getOrgInfo } = useOrg();

  const {
    firstName,
    lastName,
    email,

    uploadedFileInfo,
    logoMediaId,
    organisationId,
    organisationName,
    uniqueSlug,
  } = accountDetails;

  const handleInput = (value, type) => {
    setAccountDetails({ ...accountDetails, [type]: value });
  };

  const cleanEmail = (email) => email.toLowerCase().trim();

  const validateForm = () => {
    try {
      validate({
        role: roles.ADMIN,
        email: cleanEmail(email),
        firstName,
        lastName,
        organisationName,
        uniqueSlug,
      });
      setErrors({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleUpdate = async () => {
    setUpdating(true);

    const { error, data } = await Users.updateAccount({
      email: cleanEmail(email),
      firstName,
      lastName,
      uploadedFileInfo,
      role: user.role,
      logoMediaId,
      organisationName,
      uniqueSlug,
    });

    setUpdating(false);
    if (error) {
      if (error.statusCode === 409) {
        setErrors({ validationErrs: { [error?.data?.field]: error.message } });
      } else {
        setErrors({ httpError: error.message });
      }
    } else {
      setIsModalVisible(true);
      setUser(data);
      getOrgInfo();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdateAttempt(true);

    const isValid = validateForm();
    if (isValid) {
      handleUpdate();
    }
  };

  const getMediaUrl = async (file) => {
    setMediaLoading(true);
    const { data, error: _error } = await Media.getMediadURL({
      key: file.key,
      bucket: file.bucket,
    });

    if (!_error) {
      setMediaLoading(false);
      setMediaUrl(data);
    } else {
      setMediaLoading(false);
      setErrors({ ...errors, getImageError: 'Error loading image' });
    }
  };

  useEffect(() => {
    if (updateAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    email,
    organisationId,
    organisationName,
    uniqueSlug,
    updateAttempt,
  ]);

  useEffect(() => {
    const getAccountInfo = async () => {
      const { data, error } = await Users.getAccountInfo();

      if (!error) {
        setAccountDetails({
          ...data,
          organisationId: data?.organisation?.id,
          organisationName: data?.organisation?.name,
          uniqueSlug: data?.organisation?.uniqueSlug,
          logoMediaId: data?.logo?.id,
        });

        setMediaUrl(data?.logo?.url);
      } else {
        setErrors({
          ...errors,
          getUserInfoError: 'Error loading account details',
        });
      }
    };
    if (user.id) {
      getAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  // get image url once upload is done
  useEffect(() => {
    if (uploadedFileInfo && uploadedFileInfo.uploadedToS3) {
      getMediaUrl(uploadedFileInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFileInfo?.key, uploadedFileInfo?.uploadedToS3]);

  return (
    <>
      <Title lightSection="My" boldSection="Account" mb="8" />
      <Row mb="6" mbT="5">
        <Col w={[4, 8, 8]}>
          <T.H3 weight="bold">Account Details</T.H3>
        </Col>
        <Col w={[4, 6, 4]} mt="4">
          <T.P color="error">{errors?.getUserInfoError}</T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]} mb="5">
          <BasicInput
            label="First Name"
            handleChange={(val) => handleInput(val, 'firstName')}
            value={firstName}
            error={errors?.validationErrs?.firstName}
          />
        </Col>
        <Col w={[4, 6, 4]} mb="5">
          <BasicInput
            label="Last Name"
            handleChange={(val) => handleInput(val, 'lastName')}
            value={lastName}
            error={errors?.validationErrs?.lastName}
          />
        </Col>
      </Row>
      <Row mb="8" mbT="6">
        <Col w={[4, 6, 4]}>
          <BasicInput
            label="Email"
            handleChange={(val) => handleInput(val, 'email')}
            value={email}
            error={errors?.validationErrs?.email}
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <BasicInput
            label="Organisation"
            handleChange={(val) => handleInput(val, 'organisationName')}
            value={organisationName}
            error={errors?.validationErrs?.organisationName}
          />
        </Col>
      </Row>
      <Row mb="6" mbT="5">
        <Col w={[4, 8, 8]}>
          <T.H3 weight="bold">Customise</T.H3>
        </Col>
      </Row>
      <Row mb="6" mbT="5">
        <Col w={[4, 6, 4]} jc="center" mb="5">
          <Avatar
            status={
              !mediaUrl || fileUploading || mediaLoading ? 'loading' : 'ready'
            }
            image={mediaUrl}
            w={128}
            wT={120}
          />
          <T.P color="error" style={{ width: '100%' }}>
            {errors?.getImageError}
          </T.P>
          <FileUpload
            category={content.fileCategories.image}
            error={fileUploadError}
            setError={setFileUploadError}
            setFileInfo={(val) => handleInput(val, 'uploadedFileInfo')}
            fileInfo={uploadedFileInfo}
            uploading={fileUploading}
            setUploading={setFileUploading}
            mt="4"
          />
        </Col>
        <Col w={[4, 6, 4]}>
          <BasicInput
            label={
              <T.P weight="bold">{`${
                window.location.origin
              }${navRoutes.THERAPIST.SIGNUP.replace(
                ':uniqueSlug',
                uniqueSlug || ''
              )}`}</T.P>
            }
            handleChange={(val) => handleInput(val, 'uniqueSlug')}
            value={uniqueSlug}
            error={errors?.validationErrs?.uniqueSlug}
          />
        </Col>
      </Row>

      <Row mb="8">
        <Col w={[4, 6, 4]}>
          <Button
            text="Save changes"
            onClick={handleSubmit}
            loading={updating}
          />
        </Col>
      </Row>
      <Modal
        visible={isModalVisible}
        type="saveChangesSuccess"
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default MyAccount;
