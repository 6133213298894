import { useParams } from 'react-router-dom';

import Title from '../../../components/Title';
import Button from '../../../components/Button';
import TextWithIcon from '../../../components/TextWithIcon';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import * as S from './style';

import { content, navRoutes, roles } from '../../../constants';
import TherapistHowItWorks from './TherapistHowItWorks';

import { useAuth } from '../../../context/auth';
import { useOrg } from '../../../context/organisation';
import AddPhoneNumber from './AddPhoneNumber';

const Welcome = () => {
  const { user } = useAuth();
  const { org } = useOrg();
  const { id } = useParams();
  const numId = Number(id);
  const decideContent = () =>
    content.welcomeScreens({ orgName: org.name })[user.role][numId - 1];

  const decideNextPage = () => {
    const numberOfScreens = content.welcomeScreens({ orgName: org.name })[
      user.role
    ].length;

    if (numId < numberOfScreens) {
      if (user.role === 'CLIENT') {
        return navRoutes.CLIENT.WELCOME.replace(':id', numId + 1);
      }
      return navRoutes.THERAPIST.WELCOME.replace(':id', numId + 1);
    }

    if (user.role === 'CLIENT') {
      return navRoutes.CLIENT.DASHBOARD;
    }

    return navRoutes.THERAPIST.PROFILE;
  };

  const renderContent = () => {
    if (numId === 4 && user.role === roles.THERAPIST) {
      return (
        <TherapistHowItWorks to={decideNextPage()} text={decideContent().btn} />
      );
    } else if (numId === 5 && user.role === roles.CLIENT) {
      return (
        <AddPhoneNumber to={decideNextPage()} text={decideContent().btn} />
      );
    } else {
      return (
        <S.ButtonWrapper>
          <Col w={[4, 8, 8]}>
            <Button to={decideNextPage()} text={decideContent().btn} />
          </Col>
        </S.ButtonWrapper>
      );
    }
  };

  return (
    <S.Wrapper>
      <Title {...decideContent().title} mbM="3" />
      <Row mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">{decideContent().text}</T.P>
        </Col>
        <Col w={[4, 12, 12]} mt={4} mb={6}>
          {decideContent().links && (
            <T.P color="gray8" mb={4}>
              Below you can find useful links to user guide(s):
            </T.P>
          )}
          {decideContent().links &&
            decideContent().links.map((link) => (
              <TextWithIcon
                to={link.url}
                external="true"
                underline="true"
                text={link.label}
                icon="forwardArrow"
                weight="bold"
                mt={2}
              />
            ))}
        </Col>
      </Row>
      {renderContent()}
    </S.Wrapper>
  );
};

export default Welcome;
