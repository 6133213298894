import styled from '@emotion/styled';

export const Wrapper = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${({ theme: { spacings } }) => `${spacings[6]} ${spacings[10]}`};
  box-shadow: ${({ theme }) => theme.shadows.elevation2};

  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme: { spacings } }) =>
      `40px ${spacings[7]} 40px ${spacings[9]}`};
  }
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme: { spacings } }) =>
      `${spacings[6]} 37px ${spacings[4]} 38px`};
  }
`;
export const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Email = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue};
`;
