import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as S from './style';
import Image from '../../Image';
import theme from '../../../theme';
import AccessibilityBar from '../../AccessibilityBar';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Navbar from '../../Navbar';
import { content } from '../../../constants';

import { useAuth } from '../../../context/auth';
import CustomLogo from '../../../components/CustomLogo';
import { useOrg } from '../../../context/organisation';

const Info = ({ children, image = 'headphones', section = 'welcome' }) => {
  const { org } = useOrg();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  const { user } = useAuth();
  const { id } = useParams();

  const decideImage = () => {
    if (section === 'welcome' && id && user.role) {
      return content.welcomeScreens()[user.role][Number(id) - 1]?.image;
    }
    return image;
  };

  return (
    <>
      {!isMobile && <AccessibilityBar />}
      <S.Wrapper>
        <S.Content>
          {(image === 'blueBackground' && !isMobile && (
            <S.OnboardingHeader>
              <Link to="/" aria-label="Home Page" style={{ maxWidth: '120px' }}>
                <CustomLogo url={org?.logoUrl} />
              </Link>
              <Navbar />
            </S.OnboardingHeader>
          )) ||
            (!isMobile && (
              <Link to="/" aria-label="Home Page" style={{ maxWidth: '120px' }}>
                <CustomLogo url={org?.logoUrl} />
              </Link>
            ))}
          <div style={{ paddingTop: '115px' }}>{children}</div>
        </S.Content>
        <S.AssetWrapper>
          <Image
            image={isMobile ? `${decideImage()}S` : decideImage()}
            customStyle={{ width: '100%' }}
          />
        </S.AssetWrapper>
      </S.Wrapper>
    </>
  );
};

Info.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Info;
