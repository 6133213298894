import { useReducer, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import validate from '../../../validation/schemas/customise';
import { navRoutes, content } from '../../../constants';

import { Organisations } from '../../../api-calls';

import { useAuth } from '../../../context/auth';
import { useOrg } from '../../../context/organisation';

import {
  Button,
  Inputs,
  Grid,
  Typography as T,
  Title,
  FileUpload,
} from '../../../components';
import * as S from './style';

const { BasicInput } = Inputs;
const { Row, Col } = Grid;

const initialState = {
  uniqueSlug: '',
  httpError: '',
  validationErrs: {},
  loading: false,
};

const fileState = {
  // file upload
  id: null,
  name: '',
  key: '',
  bucketRegion: '',
  bucket: '',
  fileType: '',
  isNew: false,
  uploadedToS3: false,
  size: 0,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Customise = () => {
  const [fileUploadError, setFileUploadError] = useState(null);
  const [uploadedFileInfo, setUploadedFileInfo] = useState(fileState);
  const [fileUploading, setFileUploading] = useState(false);

  const [state, setState] = useReducer(reducer, initialState);
  const history = useHistory();
  const { user } = useAuth();
  const { getOrgInfo } = useOrg();
  const submitAttempt = useRef(false);

  const { uniqueSlug, httpError, validationErrs, loading } = state;
  useEffect(() => {
    const getData = async () => {
      const { error, data } = await Organisations.getOrganisationById({
        id: user.organisationId,
      });

      if (error) {
        setState({ httpError: error.message });
      } else {
        handleUniqueLink(data?.uniqueSlug);
      }
    };

    if (user.organisationId) {
      getData();
    }
  }, [user.organisationId]);

  const validateForm = () => {
    try {
      validate({
        uniqueSlug,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueSlug]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      setState({ loading: true });
      const { error } = await Organisations.updateOrganisation({
        id: user.organisationId,
        form: {
          uniqueSlug,
          logoFile: uploadedFileInfo,
        },
      });
      setState({ loading: false });
      if (error) {
        setState({ httpError: error.message });
      } else {
        getOrgInfo();
        history.push(navRoutes.ADMIN.SUCCESS_CREATE_ACCOUNT);
      }
    }
  };

  const handleUniqueLink = (value) => {
    const cleanValue = value?.trimStart()?.replace(' ', '-')?.toLowerCase();
    setState({ uniqueSlug: cleanValue });
  };

  return (
    <S.Wrapper onSubmit={handleSubmit}>
      <Title boldSection="Account" lightSection="Created" boldFirst />
      <Row>
        <Col w={[4, 12, 8]}>
          <T.P color="gray8" mb={6} mbM={5} style={{ maxWidth: 490 }}>
            Welcome to Duet! If you like you can upload a logo to create a
            personalized experience for your therapists and their clients.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 4, 4]}>
          <FileUpload
            error={fileUploadError}
            setError={setFileUploadError}
            setFileInfo={setUploadedFileInfo}
            fileInfo={uploadedFileInfo}
            uploading={fileUploading}
            setUploading={setFileUploading}
            category={content.fileCategories.logo}
            showInfo={false}
            placeholder="Upload organisation logo (optional)"
            mt="4"
          />
        </Col>
      </Row>

      <Row mt="6">
        <Col w={[4, 6, 6]} mb="5">
          <T.H2 weight="bold" color="gray10" mb="5" style={{ width: '100%' }}>
            Your unique link
          </T.H2>
          <T.P mb="4" color="gray8" style={{ width: '100%' }}>
            This is the link you will need to share with your therapists to sign
            up to the platform.
          </T.P>
          <T.P color="gray8" style={{ width: '100%' }}>
            To change your unique link edit the text in the input field below.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mb={7} mbM={5}>
          <T.H3 weight="bold" mb="3">{`${
            window.location.origin
          }${navRoutes.THERAPIST.SIGNUP.replace(
            ':uniqueSlug',
            uniqueSlug || ''
          )}`}</T.H3>
          <BasicInput
            placeholder="yalla-cooperative..."
            value={uniqueSlug}
            handleChange={handleUniqueLink}
            error={validationErrs.uniqueSlug}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]}>
          <T.P mt="5" color="error">
            {httpError}
          </T.P>
          <Button
            mt={7}
            mtT={5}
            mtM={5}
            text="Next"
            type="submit"
            loading={loading}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Customise;
