import { Title } from '../../../components';

import ClientsSection from '../../../components/ClientsSection';

const Feedback = () => {
  return (
    <div style={{ maxWidth: 1068 }}>
      <Title lightSection="My" boldSection="Feedback" />
      <ClientsSection toFeedback />
    </div>
  );
};

export default Feedback;
