import { useEffect, useRef, useState } from 'react';
import validate from '../../../validation/schemas/songForLife';
import NewsletterSubscribe from './NewsletterSubscribe';

import {
  Button,
  Inputs as I,
  Grid,
  Typography as T,
  Modal,
} from '../../../components';
import * as S from './style';

const { Col, Row } = Grid;

const SongForLife = ({
  artist,
  trackTitle,
  email,
  validationErrs,
  loading,
  setState,
  setStep,
}) => {
  const submitAttempt = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const validateForm = () => {
    try {
      validate({
        artist,
        trackTitle,
        email,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      setState({ loading: true });
      setStep(2);
      setState({ loading: false });
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artist, trackTitle, email]);

  return (
    <S.Wrapper>
      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        children={<NewsletterSubscribe setIsModalVisible={setIsModalVisible} />}
      />
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H1 weight="bold">Song for life</T.H1>
        </Col>
      </Row>
      <Row mt="4">
        <Col w={[4, 12, 8]}>
          <T.P color="gray8">
            As Music Therapists, we fully understand the power that music has to
            make a positive impact on people's lives no matter how severe their
            injury or illness may be. The song for life card is designed so that
            you can add a song that lifts you up or reflects a difficult time in
            your life and if you ever find yourself in a time of trouble, those
            closest to you know, know what song to play so that the power of
            music can reach you.
          </T.P>
        </Col>
      </Row>
      <S.Form onSubmit={handleSubmit}>
        <Row mt="6">
          <Col w={[4, 4, 4]}>
            <I.BasicInput
              name="artist"
              placeholder="Artist..."
              label="Artist"
              value={artist}
              handleChange={(value) => setState({ artist: value })}
              error={validationErrs.artist}
            />
          </Col>
          <Col w={[4, 4, 4]}>
            <I.BasicInput
              name="trackTitle"
              placeholder="Track title"
              label="Track title"
              value={trackTitle}
              handleChange={(value) => setState({ trackTitle: value })}
              error={validationErrs.trackTitle}
            />
          </Col>
        </Row>
        <Row mt="6">
          <Col w={[4, 8, 8]}>
            <I.BasicInput
              name="email"
              placeholder="Email address..."
              label="Email address"
              value={email}
              handleChange={(value) => setState({ email: value })}
              error={validationErrs.email}
            />
          </Col>
        </Row>

        <Row>
          <Col w={[4, 12, 4]}>
            <Button
              mt={6}
              mtT={5}
              mtM={5}
              text="Generate card"
              customColor="gradientBlue"
              type="submit"
              loading={loading}
            />
          </Col>
        </Row>
        <Row mt={6}>
          <Col w={[4, 12, 8]}>
            <T.P>
              Do you need more time to think of a song? Click the link below and
              we'll email you with tips for choosing your song and a reminder to
              download your card.
            </T.P>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 12, 4]}>
            <Button
              mt={4}
              text="I need more time"
              customColor="gradientBlue"
              type="button"
              onClick={() => setIsModalVisible(true)}
            />
          </Col>
        </Row>
      </S.Form>
    </S.Wrapper>
  );
};

export default SongForLife;
