const GENERAL = {
  HOME: '/',
  LOGIN: '/login',
  DECIDE_ROLE: '/decide-role',
  UNAUTHORIZED: '/unauthorized',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  ACCOUNT_DELETED_SUCCESS: '/account-deleted-success',
  HOW_TO_RECORD: '/how-to-record',
  NOT_FOUND: '/not-found',
  ACCESSIBILITY: '/accessibility',
  ORGANISATION_LOGIN: '/login/:organisationName',
  ORGANISATION_HOME: '/home/:organisationName',
  SONG_FOR_LIFE: '/song-for-life',
};

const CLIENT = {
  SIGNUP: '/signup',
  INVITE: '/signup/?invite=:token',
  WELCOME: '/welcome/:id',
  DASHBOARD: '/dashboard',
  MY_THERAPIST: '/my-therapist',
  THERAPY_PLAN: '/therapy-plan',
  THERAPY_GOALS: '/therapy-goals',
  CONTACT_THERAPIST: '/contact-therapist',
  PROGRAMMES: '/programmes',
  THERAPIST: '/my-therapist',
  ACCOUNT: '/account',
  ACCOUNT_DELETED: '/account-deleted',
  DELETE_ACCOUNT: '/delete-account',
  INDIVID_PROGRAMME: '/programmes/:id',
  PROTECTED_PROGRAMME: '/programmes/:id/:token?',
  SEND_UPDATE: `/programmes/:id/update`,
  SEND_FEEDBACK: `/programmes/:id/feedback`,
  SUCCESS_UPDATE: '/programmes/:id/success-update',
  SUCCESS_FEEDBACK: '/programmes/:id/success-feedback-sent',
};

const THERAPIST_BASE = '/therapist';

const THERAPIST = {
  HOME: THERAPIST_BASE,
  SIGNUP: `${THERAPIST_BASE}/signup/:uniqueSlug`,
  WELCOME: `${THERAPIST_BASE}/welcome/:id`,
  DASHBOARD: `${THERAPIST_BASE}/dashboard`,
  LIBRARY: `${THERAPIST_BASE}/library`,
  FEEDBACK: `${THERAPIST_BASE}/feedback`,
  ACCOUNT: `${THERAPIST_BASE}/account`,
  CLIENT_FEEDBACK: `${THERAPIST_BASE}/clients/:id/feedback`,

  // CREATE PROGRAM
  CREATE_PROGRAMME: `${THERAPIST_BASE}/clients/:id/programme/:programmeId/create`,
  CREATE_PROGRAMME_DESCRIPTION: `${THERAPIST_BASE}/clients/:id/programme/new/create/description`,
  CREATE_PROGRAMME_CONTENT: `${THERAPIST_BASE}/clients/:id/programme/new/create/content`,
  CREATE_PROGRAMME_CONTENT_SINGLE: `${THERAPIST_BASE}/clients/:id/programme/new/create/content/:category`,
  CREATE_PROGRAMME_REVIEW: `${THERAPIST_BASE}/clients/:id/programme/new/create/review`,
  CREATE_PROGRAMME_SUCCESS: `${THERAPIST_BASE}/clients/:id/programme/:programmeId/create/success`,
  CLIENT: `${THERAPIST_BASE}/clients/:id`,
  CLIENT_HISTORY: `${THERAPIST_BASE}/clients/:id/history`,
  CONTACT_CLIENT: `${THERAPIST_BASE}/clients/:id/contact`,
  EDIT_CLIENT: `${THERAPIST_BASE}/clients/:id/edit`,
  NEW_CLIENT: `${THERAPIST_BASE}/new-client`,
  SINGLE_PROGRAMME: `${THERAPIST_BASE}/programmes/:id`,
  SHARE_PROGRAMME: `${THERAPIST_BASE}/programmes/:id/share`,

  // EDIT PROGRAMME
  EDIT_PROGRAMME: `${THERAPIST_BASE}/programmes/:id/edit`,
  EDIT_PROGRAMME_REVIEW: `${THERAPIST_BASE}/programmes/:id/edit/review`,
  EDIT_PROGRAMME_CONTENT: `${THERAPIST_BASE}/programmes/:id/edit/content`,
  EDIT_PROGRAMME_CONTENT_SINGLE: `${THERAPIST_BASE}/programmes/:id/edit/content/:category`,
  EDIT_PROGRAMME_CONTENT_SUCCESS: `${THERAPIST_BASE}/programmes/:id/edit/success`,
  PROFILE: `${THERAPIST_BASE}/profile`,
  PROGRESS_UPDATE: `${THERAPIST_BASE}/update/:id`,
  SUCCESS_UPDATE: `${THERAPIST_BASE}/success-update`,
  HOW_IT_WORKS: `${THERAPIST_BASE}/how-it-works`,
};

const ADMIN_BASE = '/admin';

const ADMIN = {
  BASE: `${ADMIN_BASE}`,
  ALL_FEEDBACK: `${ADMIN_BASE}/all-feedback`,
  ALL_CONTENT: `${ADMIN_BASE}/all-content`,
  SUCCESS_CREATE_ACCOUNT: `${ADMIN_BASE}/success-create-account`,
  APPROVE_CREATE_ACCOUNT: `${ADMIN_BASE}/approve-create-account`,
  LOGIN: `${ADMIN_BASE}/login`,
  WELCOME: `${ADMIN_BASE}/welcome`,
  INTRO: `${ADMIN_BASE}/intro`,
  SIGNUP: `${ADMIN_BASE}/signup`,
  HOW_IT_WORKS: `${ADMIN_BASE}/how_it_works`,
  DASHBOARD: `${ADMIN_BASE}/dashboard`,
  MY_THERAPISTS: `${ADMIN_BASE}/my-therapists`,
  CUSTOMISE: `${ADMIN_BASE}/customise`,
  ACCOUNT: `${ADMIN_BASE}/account`,
};
const SUPER_ADMIN = {
  BASE: `${ADMIN_BASE}`,
  ORGANISATION_THERAPISTS: `${ADMIN_BASE}/organisations/:id/therapists`,
  ALL_ORGANISATIONS: `${ADMIN_BASE}/organisations`,
};

const EXTERNAL = {
  TERMS_OF_USE:
    'https://drive.google.com/file/d/10HNCtdLfqY8RkVvD8h0Ufxs5yR32R098/view',
  PRIVACY_POLICY:
    'https://drive.google.com/file/d/1KXb2GyC0H7NhQKFdrTQQNWjChFso0WwQ/view',

  CHROME_GOOGLE_SPEAK:
    'https://chrome.google.com/webstore/detail/voice-in-voice-typing/pjnefijmagpdjfhhkpljicbbpicelgko',
  VOICE_ALOUD_READER_ANDROID:
    'https://play.google.com/store/apps/details?id=com.hyperionics.avar&utm_source=allhunan.com',
  VOICE_ALOUD_READER_APPLE:
    'https://apps.apple.com/us/app/voice-aloud-reader/id1446876360',
  CONTACT_US: 'digital@chilternmusictherapy.co.uk',
  HOW_IT_WORKS_VIDEO:
    'https://drive.google.com/file/d/1-FtBk9o843Fdx8RiAL2XU88ReYCNyy_i/view',
  READ_USER_GUIDES:
    'https://drive.google.com/file/d/188E8zOS6nBUfZz9Tl91ThUmbTh7sr2QK/view?usp=sharing',
  USER_GUIDE_CLIENT:
    'https://drive.google.com/file/d/1MayNizKpM1CVEoqZWSzPt0NtJiPaz0ae/view?usp=sharing',
  USER_GUIDE_THERAPIST:
    'https://drive.google.com/file/d/188E8zOS6nBUfZz9Tl91ThUmbTh7sr2QK/view?usp=sharing',
  USER_GUIDE_ORGANISATION:
    'https://drive.google.com/file/d/1miKMt5NVtrsikmU0KNqc40pSJ2H3AIsh/view?usp=sharing',
};

export { GENERAL, CLIENT, THERAPIST, ADMIN, SUPER_ADMIN, EXTERNAL };
