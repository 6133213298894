const decideBorder = (type) => {
  switch (type) {
    case 'VIDEO':
      return 'purple';
    case 'DOCUMENT':
      return 'orange';
    case 'AUDIO':
      return 'red';
    default:
      return 'purple';
  }
};

export default decideBorder;
