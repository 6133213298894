const UpArrow = ({ width, height, color, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 12C21.75 17.3845 17.3845 21.75 12 21.75C6.61547 21.75 2.25 17.3845 2.25 12C2.25 6.61547 6.61547 2.25 12 2.25C17.3845 2.25 21.75 6.61547 21.75 12ZM17.0302 13.3448L12.5302 8.84484C12.3895 8.7043 12.1988 8.62535 12 8.62535C11.8012 8.62535 11.6105 8.7043 11.4698 8.84484L6.96984 13.3448C6.83513 13.4866 6.76114 13.6755 6.76364 13.871C6.76614 14.0666 6.84495 14.2535 6.98325 14.3918C7.12155 14.5301 7.3084 14.6089 7.50398 14.6114C7.69955 14.6139 7.88836 14.5399 8.03016 14.4052L12 10.4358L15.9698 14.4052C16.1116 14.5399 16.3005 14.6139 16.496 14.6114C16.6916 14.6089 16.8785 14.5301 17.0168 14.3918C17.1551 14.2535 17.2339 14.0666 17.2364 13.871C17.2389 13.6755 17.1649 13.4866 17.0302 13.3448Z"
      fill={color || 'white'}
    />
  </svg>
);

export default UpArrow;
