import { useState } from 'react';
import { Col, Row } from '../../../components/Grid';
import { Dropdown } from '../../../components/Inputs';
import { userRoles, userStatuses } from '../../../constants';
import * as T from '../../../components/Typography';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { Users } from '../../../api-calls';
import { useAuth } from '../../../context/auth';

const options = [
  {
    label: 'Admin',
    value: userRoles.ADMIN,
  },
  { label: 'Therapist', value: userRoles.THERAPIST },
];
const statusOptions = [
  {
    label: 'Activate',
    value: userStatuses.ACTIVE,
  },
  { label: 'Deactivate', value: userStatuses.DEACTIVATED },
];

const readableName = {
  [userRoles.ADMIN]: 'Admin',
  [userRoles.THERAPIST]: 'Therapist',
};
const readableStatus = {
  [userStatuses.ACTIVE]: 'Active',
  [userStatuses.DEACTIVATED]: 'Deactivated',
};

const UserRow = ({
  user: { status, roles, id, firstName, lastName, email },
  setError,
  setTherapistsInfo,
  key,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [selectedRole, setSelectedRole] = useState();
  const [confirmUpdateRole, setConfirmUpdateRole] = useState({
    open: false,
    addingAdmin: false,
  });

  const { user: loggedInUser } = useAuth();

  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState({
    open: false,
    deactivating: false,
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const onCancel = () => {
    setConfirmUpdateStatus({
      open: false,
      deactivating: false,
    });

    setConfirmUpdateRole({
      open: false,
      addingAdmin: false,
    });
    setSelectedStatus(status);
    setSelectedRole(roles[0]);
  };

  const handleAddRole = async () => {
    setConfirmUpdateRole({
      open: false,
      addingAdmin: false,
    });
    const { data, error } = await Users.addRole({
      userId: id,
      role: selectedRole,
    });
    if (error) {
      setError(error.message);
    } else {
      setTherapistsInfo((old) =>
        old.map((user) => (user.id === id ? data : user))
      );
      setError('');
      setUpdateSuccess(true);
    }
  };

  const handleDeactivate = async () => {
    const statusToUpdate = confirmUpdateStatus.deactivating
      ? userStatuses.DEACTIVATED
      : userStatuses.ACTIVE;

    setConfirmUpdateStatus({
      open: false,
      deactivating: false,
    });
    const { error, data } = await Users.updateUserStatus({
      id: id,
      status: statusToUpdate,
    });
    if (error) {
      setError(error.message);
    } else {
      setTherapistsInfo((old) =>
        old.map((user) => (user.id === id ? data : user))
      );
      setError('');
      setUpdateSuccess(true);
    }
  };

  return (
    <div key={key}>
      <Row mb="6" mbM="4" ai="center">
        <Col w={[4, 2, 2]}>
          <T.P color="gray9" weight="700">
            {firstName} {lastName}
          </T.P>
        </Col>
        <Col w={[4, 2, 4]}>
          <T.P color="gray9" weight="700">
            {email}
          </T.P>
        </Col>
        <Col w={[4, 4, 3]}>
          {loggedInUser.id !== id ? (
            /* Activate / deactivate */
            <Dropdown
              allowClear={false}
              color="gray8"
              selected={readableStatus[selectedStatus]}
              handleChange={(value) => {
                setSelectedStatus(value);
                setConfirmUpdateStatus({
                  open: true,
                  deactivating: value === userStatuses.DEACTIVATED,
                });
              }}
              options={statusOptions.filter((e) => e.value !== status)}
              placeholder="Select..."
            />
          ) : (
            <T.P color="gray9">(You)</T.P>
          )}
        </Col>
        <Col w={[4, 4, 3]}>
          {roles.length === 1 ? (
            // add new role
            <Dropdown
              allowClear={false}
              color="gray8"
              selected={readableName[roles[0]]}
              handleChange={(value) => {
                setConfirmUpdateRole({
                  open: true,
                  addingAdmin: value === userRoles.ADMIN,
                });
                setSelectedRole(value);
              }}
              options={options.filter((e) => e.value !== roles[0])}
              placeholder="Select..."
              disabled={status === userStatuses.DEACTIVATED}
            />
          ) : (
            <T.P color="gray9">Admin & Therapist</T.P>
          )}
        </Col>
      </Row>
      <Modal
        visible={confirmUpdateRole.open}
        setIsModalVisible={(open) =>
          setConfirmUpdateRole((prev) => ({ ...prev, open }))
        }
        onCancel={onCancel}
        variant="warning"
      >
        <T.H3 mb="2" weight="bold">
          Are you sure?
        </T.H3>
        <T.P color="gray9" mb="5">
          {confirmUpdateRole.addingAdmin
            ? 'This will give them full privileges to manage this organisation. (This cannot be undone.)'
            : 'This will give them the ability to setup therapist account. (This cannot be undone.)'}
        </T.P>
        <Button
          handleClick={handleAddRole}
          text="Confirm"
          mb="3"
          customColor="pink"
        />
        <Button handleClick={onCancel} text="Go back" m="0" variant="gray" />
      </Modal>

      <Modal
        visible={confirmUpdateStatus.open}
        setIsModalVisible={(open) =>
          setConfirmUpdateStatus((prev) => ({ ...prev, open }))
        }
        onCancel={onCancel}
        variant="warning"
      >
        <T.H3 mb="2" weight="bold">
          Are you sure?
        </T.H3>
        {confirmUpdateStatus.deactivating ? (
          <T.P color="gray9" mb="5">
            This will prevent them from being able to log into their account.
          </T.P>
        ) : (
          <T.P color="gray9" mb="5">
            This will give them the ability to login and use the tool again.
          </T.P>
        )}
        <Button
          handleClick={handleDeactivate}
          text="Confirm"
          mb="3"
          customColor="pink"
        />
        <Button handleClick={onCancel} text="Go back" m="0" variant="gray" />
      </Modal>

      <Modal
        type="updateSuccess"
        title="Successfully updated"
        description="All your changes have been saved."
        visible={updateSuccess}
        setIsModalVisible={setUpdateSuccess}
        titleProps={{ weight: 'bold' }}
      />
    </div>
  );
};
export default UserRow;
