import { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { Media, songForLife } from '../../../api-calls';

import ShareBox from './ShareBox';

import { Button, Grid, Typography as T, Image } from '../../../components';
import * as S from './style';
const { Col, Row } = Grid;

const uploadCard = async () => {
  const file = await html2canvas(document.querySelector('#pdf'));
  const { data } = await Media.getSignedURLForPublic();

  file.toBlob(async (blob) => {
    if (blob === null) return;
    await Media.uploadToS3({
      signedURL: data.url,
      file: blob,
      options: {
        headers: { 'Content-Type': 'image/jpeg' },
      },
    });
  }, 'image/jpeg');
  return data.url;
};

const GenerateSongCard = ({ artist, trackTitle, email }) => {
  const [url, setUrl] = useState('');

  const createPDF = async () => {
    const pdf = new jsPDF('portrait', 'pt', 'a4');
    const data = await html2canvas(document.querySelector('#pdf'));
    const img = data.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(img);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, 'PNG', 32, 100, pdfWidth - 64, pdfHeight);
    pdf.save('song-for-life.pdf');
  };

  useEffect(() => {
    const getUrl = async () => {
      const _url = await uploadCard();
      setUrl(_url.split('?')[0]);
      await songForLife.createSongForLife({
        artist,
        trackTitle,
        email,
        cardImage: _url.split('?')[0],
      });
    };

    if (!url) {
      getUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H1 weight="bold">Success!</T.H1>
        </Col>
      </Row>
      <Row mt="6" mb="3">
        <Col w={[4, 12, 8]}>
          <T.P color="gray8">
            Congrats, here’s your Song for Life card. Click the download button
            below to save your card.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 8]}>
          <S.CardWrapper id="pdf">
            <S.CardContainer>
              <Image image="cMTFullLogo" />
              <T.H3 mt="4" mb="3" weight="bold" ta="center">
                If I ever have a brain injury or dementia, please play me this
                song:
              </T.H3>
              <S.Box>
                <T.P small color="neutral90">
                  {artist}
                </T.P>
              </S.Box>
              <S.Box>
                <T.P small color="neutral90">
                  {trackTitle}
                </T.P>
              </S.Box>
            </S.CardContainer>
          </S.CardWrapper>
        </Col>
      </Row>
      <ShareBox url={url} />
      <Row>
        <Col w={[4, 12, 4]}>
          <Button
            text="Download card"
            customColor="gradientBlue"
            type="submit"
            onClick={createPDF}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default GenerateSongCard;
