import { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Typography as T, Button, Grid } from '../../../components';

import { navRoutes, userStatuses } from '../../../constants';
import { Programmes } from '../../../api-calls';
import { BasicInput } from '../../../components/Inputs';
import validate from '../../../validation/schemas/create-protected-programme';
import Copy from '../../../components/Copy';

import * as S from './style';

const { Row, Col } = Grid;

const ActiveClientContent = ({
  firstInitial,
  lastInitial,
  postcode,
  history,
  password,
  setPassword,
  passwordError,
  handleSubmit,
  loading,
  httpError,
}) => {
  return (
    <>
      <Row mt={5}>
        <Col w={[4, 6, 6]}>
          <T.P color="gray8">
            {firstInitial} {lastInitial} {postcode} will be notified that they
            can now view this content when they next log in.
          </T.P>
        </Col>
      </Row>
      <Row mt={6}>
        <Col w={[4, 9, 4]}>
          <Button
            variant="primary"
            text="Return home"
            handleClick={() => history.push(navRoutes.THERAPIST.DASHBOARD)}
          />
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 6, 6]}>
          <T.H2 mb={4} weight="regular">
            Share content via link
          </T.H2>
          <T.P color="gray8">
            You can also share this programme by sending them a password
            protected link.
          </T.P>
          <T.P color="gray8" mt={5}>
            To do this, first create a password.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mt={5}>
          <BasicInput
            label="Create password"
            placeholder="Create password here..."
            type="password"
            value={password}
            handleChange={(value) => setPassword(value)}
            error={passwordError}
            showPasswordInfo
          />
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 9, 4]}>
          {httpError && <T.P>{httpError}</T.P>}
          <Button
            variant="primary"
            text="Get Link"
            handleClick={handleSubmit}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

const NoneActiveClientContent = ({
  firstInitial,
  lastInitial,
  postcode,
  password,
  setPassword,
  passwordError,
  handleSubmit,
  loading,
  httpError,
}) => {
  return (
    <>
      <Row mt={5}>
        <Col w={[4, 6, 6]}>
          <T.P color="gray8">
            {firstInitial} {lastInitial} {postcode} has not yet signed up to the
            platform. If they are not able to or do not wish to register for any
            reason, you can also share this programme by sending them a password
            protected link.
          </T.P>
          <T.P color="gray8" mt={5}>
            To do this, first create a password.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mt={5}>
          <BasicInput
            label="Create password"
            placeholder="Create password here..."
            type="password"
            value={password}
            handleChange={(value) => setPassword(value)}
            error={passwordError}
            showPasswordInfo
          />
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 9, 4]}>
          {httpError && <T.P>{httpError}</T.P>}
          <Button
            variant="primary"
            text="Get Link"
            handleClick={handleSubmit}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

const NoneActiveClientContentSuccess = ({ programmeId, token }) => {
  return (
    <>
      <Row mt={5}>
        <Col w={[4, 6, 6]}>
          <T.P color="gray8">
            Great! Here’s your password protected password. For safety reasons
            this password will expire and need to be updated after every 30
            days.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 6]} mt={5}>
          <Copy
            inviteToken={`${
              window.location.origin
            }${navRoutes.CLIENT.PROTECTED_PROGRAMME.replace(
              ':id',
              programmeId
            ).replace(':token?', token)}`}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 6]} mt={5}>
          <T.Link
            to={{
              pathname: navRoutes.THERAPIST.SHARE_PROGRAMME.replace(
                ':id',
                programmeId
              ),
              state: {
                updatePassword: true,
              },
            }}
            underline
            color="gray10"
            weight="bold"
          >
            Update Password
          </T.Link>{' '}
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 9, 4]}>
          <Button
            variant="primary"
            text="Return home"
            to={navRoutes.THERAPIST.SINGLE_PROGRAMME.replace(
              ':id',
              programmeId
            )}
          />
        </Col>
      </Row>
    </>
  );
};

const Success = ({ clientDetails }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [httpError, setHttpError] = useState('');
  const history = useHistory();
  const submitAttempt = useRef();
  const { programmeId } = useParams();
  const { firstInitial, lastInitial, postcode, status } = clientDetails;

  const validateForm = () => {
    try {
      validate({
        password,
      });
      setPasswordError('');
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setPasswordError(error.inner.password);
      }
      return false;
    }
  };

  const createPassword = async () => {
    const valid = validateForm();
    if (!valid) return;

    setLoading(true);
    const { error, data } = await Programmes.updateProgrammePassword({
      id: programmeId,
      password,
      action: 'CREATE',
    });
    setLoading(false);
    if (error) {
      setHttpError(error.message);
    } else {
      setToken(data.token);
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <>
      <Row mt={5}>
        <Col w={[4, 9, 7]}>
          <S.HeadlineWrapper>
            <T.H1 color="gray10">
              <strong>New Programme</strong> successfully added
            </T.H1>
          </S.HeadlineWrapper>
        </Col>{' '}
      </Row>
      {status === userStatuses.ACTIVE && !token && (
        <ActiveClientContent
          firstInitial={firstInitial}
          lastInitial={lastInitial}
          postcode={postcode}
          history={history}
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          handleSubmit={createPassword}
          loading={loading}
          httpError={httpError}
        />
      )}
      {status !== userStatuses.ACTIVE && !token && (
        <NoneActiveClientContent
          firstInitial={firstInitial}
          lastInitial={lastInitial}
          postcode={postcode}
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          handleSubmit={createPassword}
          loading={loading}
          httpError={httpError}
        />
      )}
      {token && (
        <NoneActiveClientContentSuccess
          programmeId={programmeId}
          token={token}
        />
      )}
    </>
  );
};

export default Success;
