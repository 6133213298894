import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: row;
  justify-content: flex-start;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

export const AssetWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
`;

export const Content = styled.main`
  width: 50%;
  padding: ${({ theme: { spacings } }) =>
    `40px ${spacings[5]} 40px ${spacings[10]}`};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ theme }) => theme.media.tablet} {
    width: 100%;
    padding: ${({ theme: { spacings } }) => `${spacings[7]} ${spacings[9]}`};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: ${({ theme: { spacings } }) =>
      `${spacings[0]} 36px ${spacings[6]} 36px`};
  }
`;

export const OnboardingHeader = styled.header`
  position: absolute;
  width: 100%;
  padding: ${({ theme: { spacings } }) => `${spacings[6]} ${spacings[10]}`};
  top: 0px !important;
  left: 0px !important;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: ${({ theme: { spacings } }) => spacings[1]};
    background: ${({ theme: { gradients } }) => gradients.random};
    ${({ theme }) => theme.media.mobile} {
      height: 1px;
      bottom: -1px;
    }
  }
`;
