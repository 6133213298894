import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Title from '../../../components/Title';
import {
  Button,
  Typography as T,
  Grid,
  Inputs,
  TextWithIcon,
} from '../../../components';

import * as S from './style';
import { Users } from '../../../api-calls';

import { navRoutes } from '../../../constants';
import { useAuth } from '../../../context/auth';

const { Checkbox } = Inputs;
const { Col, Row } = Grid;

const HowItWorks = () => {
  const history = useHistory();
  const { user, setUser } = useAuth();

  const [watchedTutorial, setWatchedTutorial] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (user.watchedTutorial) {
      setWatchedTutorial(true);
    }
  }, [user.watchedTutorial]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await Users.therapistHowItWorks(watchedTutorial);

    if (error) {
      setError(error.message);
    } else {
      setUser({ ...user, watchedTutorial: true });
      history.replace(navRoutes.THERAPIST.DASHBOARD);
    }
  };

  return (
    <S.Wrapper>
      <Title
        lightSection="how it works"
        boldSection="Understand"
        boldFirst
        mbM="3"
      />
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            It is really important to understand how to use this platform
            properly in order that you provide the best and safest experience
            for your clients.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            To help with this, we require all therapists to watch a video that
            will explain how to use this tool. If you like you can also arrange
            a live tutorial by emailing{' '}
            <T.Link
              to="mailto: digital@duetplatform.com"
              weight="bold"
              color="gray8"
              external
            >
              digital@duetplatform.com
            </T.Link>
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mt="6" mb="7" ml="5">
          <TextWithIcon
            to={navRoutes.EXTERNAL.HOW_IT_WORKS_VIDEO}
            external="true"
            underline="true"
            text="View video"
            icon="forwardArrow"
            weight="bold"
          />
        </Col>
        <Col w={[4, 12, 12]} mb="6">
          <Checkbox
            checked={watchedTutorial}
            label="I confirm I have watched the video"
            handleChange={setWatchedTutorial}
          />
        </Col>
      </Row>
      <S.ButtonWrapper>
        <Col w={[4, 8, 8]}>
          {error && (
            <T.P color="error" mt="3">
              {error}
            </T.P>
          )}
          <Button
            text="Confirm"
            handleClick={handleSubmit}
            style={{ alignSelf: 'flex-end' }}
            disabled={!watchedTutorial}
          />
          <Button
            text="Go back"
            variant="gray"
            mt="5"
            handleClick={() => history.goBack()}
            style={{ alignSelf: 'flex-start' }}
          />
        </Col>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default HowItWorks;
