const pickRandomValue = (arr) => arr[Math.floor(Math.random() * arr.length)];

const red = 'linear-gradient(0deg, #D32F70 0%, #D32F70 52.6%, #DB6869 100%)';
const orange = 'linear-gradient(180deg, #F7931D 0%, #F37658 100%)';
const purple = 'linear-gradient(180deg, #6D759C 0%, #83459A 100%)';
const green = 'linear-gradient(180deg, #009C54 0%, #00A19A 100%)';
const blue = 'linear-gradient(90deg, #2D57A5 0%, #188ECE 94.17%)';
const rainbow =
  'linear-gradient(90deg, #0B9DD9 0%, #284DA0 12.5%, #83459A 25%, #E7445E 37.5%, #F47724 51.56%, #FCCF3D 65.62%, #7BC361 82.81%, #4EC6EA 100%)';

export default {
  red,
  orange,
  purple,
  green,
  rainbow,
  blue,
  white: 'linear-gradient(#fff 0 0)',
  random: () => pickRandomValue([red, orange, purple, green]),
};
