import { Switch } from 'react-router-dom';
import { Route } from './../../components';

import Customise from './Customise';
import Signup from './Signup';
import Welcome from './Welcome';
import Intro from './Intro';
import HowItWorks from './HowItWorks';
import Dashboard from './Dashboard';
import Success from './Success';
import Approve from './Approve';
import AllOrganisations from './AllOrganisations';
import Therapists from './Therapists';
import { navRoutes, roles } from './../../constants';
import Library from './../Therapist/Library';
import Feedback from './Feedback';

import MyAccount from './MyAccount';

function AdminRouter() {
  return (
    <>
      <Switch>
        {/* Admin Pages */}
        <Route
          exact
          path={navRoutes.ADMIN.ACCOUNT}
          Component={MyAccount}
          isPrivate
          allowedRoles={[roles.ADMIN]}
        />
        <Route
          exact
          path={navRoutes.ADMIN.ALL_FEEDBACK}
          Component={Feedback}
          isPrivate
          allowedRoles={[roles.ADMIN, roles.SUPER_ADMIN]}
        />
        <Route
          exact
          path={navRoutes.ADMIN.HOW_IT_WORKS}
          Component={HowItWorks}
          layout="info"
          image="dancing"
          isPrivate
          allowedRoles={[roles.ADMIN, roles.SUPER_ADMIN]}
        />

        <Route
          exact
          path={navRoutes.ADMIN.DASHBOARD}
          Component={Dashboard}
          isPrivate
          allowedRoles={[roles.ADMIN, roles.SUPER_ADMIN]}
        />
        <Route
          exact
          path={navRoutes.ADMIN.CUSTOMISE}
          Component={Customise}
          layout="onboarding"
          isPrivate
          allowedRoles={[roles.ADMIN]}
        />

        <Route
          exact
          path={navRoutes.ADMIN.ALL_CONTENT}
          Component={Library}
          isPrivate
          allowedRoles={[roles.ADMIN, roles.SUPER_ADMIN]}
        />
        <Route
          path={navRoutes.ADMIN.MY_THERAPISTS}
          Component={Therapists}
          layout="general"
          isPrivate
          allowedRoles={[roles.ADMIN, roles.SUPER_ADMIN]}
        />
        <Route
          path={navRoutes.SUPER_ADMIN.ORGANISATION_THERAPISTS}
          Component={Therapists}
          layout="general"
          isPrivate
          allowedRoles={[roles.SUPER_ADMIN]}
          superAdmin
        />

        <Route
          exact
          path={navRoutes.ADMIN.SUCCESS_CREATE_ACCOUNT}
          Component={Success}
          layout="info"
          image="welcomeOrange"
          isPrivate
          allowedRoles={[roles.ADMIN]}
        />
        <Route
          exact
          path={navRoutes.SUPER_ADMIN.ALL_ORGANISATIONS}
          Component={AllOrganisations}
          layout="general"
          isPrivate
          allowedRoles={[roles.SUPER_ADMIN]}
        />
        <Route
          exact
          path={navRoutes.ADMIN.APPROVE_CREATE_ACCOUNT}
          Component={Approve}
          layout="info"
          image="welcomeOrange"
          isPrivate
          allowedRoles={[roles.ADMIN]}
        />
        <Route
          exact
          path={navRoutes.ADMIN.INTRO}
          Component={Intro}
          layout="info"
          image="dancing"
          loggedOutOnly
        />
      </Switch>

      <Switch>
        {/* LOGGED OUT */}
        <Route
          exact
          path={navRoutes.ADMIN.WELCOME}
          Component={Welcome}
          layout="info"
          image="dancing"
          loggedOutOnly
        />

        <Route
          path={navRoutes.ADMIN.SIGNUP}
          Component={Signup}
          layout="onboarding"
          loggedOutOnly
        />
      </Switch>
    </>
  );
}

export default AdminRouter;
