import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Organisations } from '../../../api-calls';
import { useAuth } from '../../../context/auth';

import { Title, Typography as T, Grid } from '../../../components';

import UserRow from './userRow';

const { Col, Row } = Grid;

function Therapists({ superAdmin }) {
  const [therapistsInfo, setTherapistsInfo] = useState([]);
  const [organisation, setOrganisation] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState([]);
  const { user } = useAuth();
  const { id } = useParams();
  useEffect(() => {
    const getTherapists = async () => {
      const { data } = await Organisations.getTherapistsByOrganisationId({
        id: superAdmin ? id : user.organisationId,
      });
      setTherapistsInfo(data);
    };
    const getOrganisation = async () => {
      const { data } = await Organisations.getOrganisationById({
        id: id,
      });

      setOrganisation(data);
    };

    getTherapists();
    if (superAdmin) {
      getOrganisation();
    }
  }, [user.organisationId, id, superAdmin]);
  return (
    <div>
      {superAdmin ? (
        <Title
          mb="7"
          lightSection={`at ${organisation.name}`}
          boldSection="Therapists"
          boldFirst
        />
      ) : (
        <Title mb="7" lightSection="My" boldSection="Therapists" />
      )}
      <Row mb="7">
        <Col w={[4, 7, 7]}>
          <T.P>
            On this page you can view{' '}
            {superAdmin
              ? `${organisation.name}'s therapists`
              : 'all your therapists'}
            , deactivate accounts and give therapists administrator permissions.
          </T.P>
        </Col>
      </Row>
      <Row mb="6">
        <Col w={[4, 2, 2]}>
          <T.P color="gray9" weight="700">
            Therapist
          </T.P>
        </Col>
        <Col w={[4, 2, 4]}>
          <T.P color="gray9" weight="700">
            Email
          </T.P>
        </Col>
        <Col w={[4, 4, 3]}>
          <T.P color="gray9">Action</T.P>
        </Col>
        <Col w={[4, 4, 3]}>
          <T.P color="gray9">Roles</T.P>
        </Col>
      </Row>
      {therapistsInfo?.length
        ? therapistsInfo.map((user) => (
            <UserRow
              user={user}
              setError={setError}
              setTherapistsInfo={setTherapistsInfo}
              key={user.id}
            />
          ))
        : null}
      {/* <S.ButtonWrapper>
        <Col w={[4, 3, 3]}>
          {error && <T.P color="error">{error}</T.P>}

          <Button to={navRoutes.ADMIN.DASHBOARD} text="Export change log" />
        </Col>
      </S.ButtonWrapper> */}
    </div>
  );
}

export default Therapists;
