const Email = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M19.97 3.906H4.146c-.7.001-1.37.29-1.864.802a2.789 2.789 0 00-.774 1.933v11.718c.001.725.28 1.42.774 1.933a2.594 2.594 0 001.864.802h15.826c.7-.001 1.37-.29 1.864-.802a2.789 2.789 0 00.774-1.933V6.641c-.001-.725-.28-1.42-.774-1.933a2.594 2.594 0 00-1.864-.802zm-.667 4.523l-6.783 5.469a.737.737 0 01-.925 0L4.813 8.429a.773.773 0 01-.293-.52.809.809 0 01.152-.583.762.762 0 01.507-.296.728.728 0 01.559.166l6.32 5.095 6.32-5.095a.734.734 0 011.05.144.802.802 0 01-.125 1.09z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Email;
