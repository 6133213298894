import axios from 'axios';
import handleError from './format-error';

const SONG_FOR_LIFE_BASE = '/song-for-life';

const createSongForLife = async (form) => {
  try {
    const { data } = await axios.post(`${SONG_FOR_LIFE_BASE}/`, form);
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

export { createSongForLife };
