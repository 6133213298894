import React from 'react';
import Image from '../Image';
import Icon from '../Icon';
import * as S from './style';
import * as T from '../Typography';
import theme from '../../theme';

import { useMediaQuery } from 'react-responsive';

const Footer = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  return (
    <S.Wrapper>
      <S.Email
        href="mailto:irene@chilternmusictherapy.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        <Icon icon="email" color="blue" width="20px" mr="2" />
        <T.P mr="3" small color="blue">
          {isMobile ? 'Helpdesk' : 'Contact Tech Support'}
        </T.P>
      </S.Email>
      <S.Link
        href="https://www.chilternmusictherapy.co.uk/"
        target="_blank"
        rel="noreferrer"
      >
        <Image image="logo" width="100px" mb="3" />
        <T.P weight="light" ta="right" small>
          © Duet by Chiltern Music Therapy
        </T.P>
      </S.Link>
    </S.Wrapper>
  );
};

export default Footer;
