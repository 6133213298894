import { fields, createSchema, validate as _validate } from '..';

const { uniqueSlug } = fields;

const Customise = createSchema({
  uniqueSlug,
});

const validate = (data) => _validate(Customise, data);

export default validate;
