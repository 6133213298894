import styled from '@emotion/styled';

export const Wrapper = styled.div``;

export const Form = styled.form``;

export const CardWrapper = styled.div`
  padding: 12px;
  border-radius: 40px;
  background: ${({ theme }) => theme.gradients.green};
`;

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 25px;
  max-width: 588px;
  padding: 18px 58px 36px 58px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    padding: 18px 32px 36px 32px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 18px 24px 36px 24px;
  }
`;

export const Box = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray1};
  margin-bottom: 4px;
  border-radius: 12px;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
  ${({ theme }) => theme.media.tablet} {
    max-width: 100%;
  }
`;

export const SocialWrapper = styled.div`
  flex: 1;
`;

export const SocialImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;

export const NewsLetterWrapper = styled.div`
  background-color: white;
  border-radius: 25px;
  max-width: 588px;
  padding: 18px 58px 36px 58px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    padding: 18px 32px 36px 32px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 18px 24px 36px 24px;
  }
`;
