import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 520px;
  ${({ theme }) => theme.media.tablet} {
    padding-top: 40px;
    flex-grow: 1;
    max-width: none;
  }
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => theme.media.mobile} {
    height: 100%;
    margin: auto 0 0 0;
  }
`;
export const Link = styled(RouterLink)`
  font-weight: 700;
  text-decoration: underline !important;
  color: ${theme.colors.blue} !important;
`;
