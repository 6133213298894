import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Typography as T, Grid, Button, SubHeader } from '../../../components';
import Modal from '../../../components/Modal';

import { TherapistClients } from '../../../api-calls';
import { userRoles, userStatuses } from '../../../constants';
import Title from '../../../components/Title';
import { Users } from '../../../api-calls';
import { useAuth } from '../../../context/auth';
const headers = [
  { label: 'Programme (date)', key: 'programmeCreatedAt' },
  { label: 'Feedback (date)', key: 'feedbackCreatedAt' },
  { label: 'Client update (date)', key: 'progressUpdateCreatedAt' },
  {
    label: 'Were the demos clear for this week? (1 to 5)',
    key: 'clearDemos',
  },
  {
    label: "Did you find this week's digital resources enjoyable? (1 to 5)",
    key: 'enjoyableResources',
  },
  {
    label:
      'What did you like the most and least when using the digital platform this week?',
    key: 'likeMostAndLeast',
  },
  {
    label: 'Were the instructions clear for this week? (1 to 5)',
    key: 'enjoyableResources',
  },
];

const { Row, Col } = Grid;
const ClientFeedback = () => {
  const { user } = useAuth();

  const csvRef = useRef();
  const [state, setState] = useState({
    firstInitial: '',
    lastInitial: '',
    postcode: '',
    therapyBackground: '',
    therapyGoals: [],
    status: userStatuses.ACTIVE,
    updateStatusLoading: false,
  });

  const [error, setError] = useState({});
  const [data, setData] = useState([]);
  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const getClientById = async () => {
      const { error, data } = await TherapistClients.getClientById({ id });
      if (error) {
        setError({ httpError: error.message });
      }
      setState(data);
    };

    getClientById();
  }, [id]);

  const getData = async () => {
    const { data, error } = await TherapistClients.getClientFeedbackCSV({
      clientId: id,
    });
    if (!error) {
      data.forEach((row) => {
        row.programmeCreatedAt = moment(row.programmeCreatedAt).format(
          'DD/MM/YYYY'
        );
        row.feedbackCreatedAt = moment(row.feedbackCreatedAt).format(
          'DD/MM/YYYY'
        );
        row.progressUpdateCreatedAt = moment(
          row.progressUpdateCreatedAt
        ).format('DD/MM/YYYY');
      });

      setData(data);
      csvRef.current.link.click();
    }
  };

  const onCancel = () => {
    setConfirmUpdateStatus(false);
  };

  const handleDeactivate = async () => {
    setState({ ...state, updateStatusLoading: true });
    const statusToUpdate =
      state.status === userStatuses.ACTIVE
        ? userStatuses.DEACTIVATED
        : userStatuses.ACTIVE;

    const { error } = await Users.updateUserStatus({
      id: id,
      status: statusToUpdate,
    });
    if (error) {
      setError(error.message);
    } else {
      setError('');
    }
    setState((_state) => ({
      ..._state,
      updateStatusLoading: false,
      status: statusToUpdate,
    }));
    setConfirmUpdateStatus(false);
  };

  return (
    <div>
      {state.status === userStatuses.DELETED ? (
        <T.P>Discharged Client</T.P>
      ) : (
        <Title
          boldSection={state.firstInitial}
          lightSection={`${state.lastInitial} ${state.postcode} ${
            state.status === userStatuses.DEACTIVATED ? '(Deactivated)' : ''
          }`}
          boldFirst
        />
      )}
      <SubHeader>FEEDBACK</SubHeader>

      <Row mb="5">
        <Col w={[4, 12, 6]}>
          <T.P color="gray8">
            Every time you send your client a home programme with content to
            work on in between sessions, the client can let you know how they
            found working on the content that week
          </T.P>
        </Col>
      </Row>

      <Row mb="5">
        <Col w={[4, 12, 6]}>
          <T.P color="gray8">
            Feedback on usage and engagement is requested in order to continue
            improving our app during initial implementation
          </T.P>
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 12, 4]}>
          {error.httpError && <T.P color="error">{error.httpError}</T.P>}
          <Button
            text="Export feedback"
            to=""
            onClick={getData}
            variant="secondary"
          />

          <CSVLink
            aria-label="Export feedback"
            data={data}
            headers={headers}
            style={{ width: '100%' }}
            filename={`${state.firstInitial} ${state.lastInitial} ${state.postcode}-feedback.csv`}
            ref={csvRef}
          ></CSVLink>
        </Col>
      </Row>
      {(user.role === userRoles.SUPER_ADMIN ||
        user.role === userRoles.ADMIN) && (
        <Row mb="8">
          <Col w={[4, 12, 4]}>
            {error.httpError && <T.P color="error">{error.httpError}</T.P>}
            <Button
              text={
                state.status === userStatuses.ACTIVE ? 'Deactivate' : 'Activate'
              }
              to=""
              onClick={() => setConfirmUpdateStatus(true)}
            />
          </Col>
        </Row>
      )}

      <Modal
        visible={confirmUpdateStatus}
        setIsModalVisible={setConfirmUpdateStatus}
        onCancel={onCancel}
        variant="warning"
      >
        <T.H3 mb="2" weight="bold">
          Are you sure?
        </T.H3>
        {state.status === userStatuses.ACTIVE ? (
          <T.P color="gray9" mb="5">
            This will prevent them from being able to log into their account.
          </T.P>
        ) : (
          <T.P color="gray9" mb="5">
            This will give them the ability to login and use the tool again.
          </T.P>
        )}
        <Button
          handleClick={handleDeactivate}
          text="Confirm"
          mb="3"
          customColor="pink"
          loading={state.updateStatusLoading}
        />
        <Button handleClick={onCancel} text="Go back" m="0" variant="gray" />
      </Modal>
    </div>
  );
};

export default ClientFeedback;
