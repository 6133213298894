import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Title from '../../../components/Title';
import * as T from '../../../components/Typography';

import Button from '../../../components/Button';
import { Row, Col } from '../../../components/Grid';

import { Users } from '../../../api-calls';
import { navRoutes, userRoles } from '../../../constants';
import { useAuth } from '../../../context/auth';

const DecideRole = () => {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, role) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await Users.setActiveRole({
      role,
    });
    setLoading(false);
    if (error) {
      setError({ server: error.message });
    } else {
      setUser({ ...user, role });
    }
  };

  if (user.role) {
    if (user.role === userRoles.THERAPIST) {
      history.replace(navRoutes.THERAPIST.DASHBOARD);
    }

    if (user.role === userRoles.ADMIN) {
      history.replace(navRoutes.ADMIN.DASHBOARD);
    }

    return null;
  }
  return (
    <>
      <Title boldSection="Select" lightSection="Account" boldFirst />
      <Row mb="8">
        <Col w={[4, 8, 6]}>
          <T.P color="black">
            Please select whether you wish to view your therapist or admin
            account
          </T.P>
        </Col>
      </Row>

      {error?.server && (
        <Row mb="4">
          <Col w={[4, 8, 4]}>
            <T.P color="secondary">{error.server}</T.P>
          </Col>
        </Row>
      )}
      {user.roles.map((role) => (
        <Row key={role} mb={5}>
          <Col w={[4, 8, 4]}>
            <Button
              text={role}
              onClick={(e) => handleSubmit(e, role)}
              loading={loading}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default DecideRole;
