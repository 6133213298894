const ForwardArrow = ({ width, height, color, ...props }) => (
  <svg
    width={width || '30'}
    height={height || '30'}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 17.8125C12.9396 17.8124 12.7585 17.7574 12.6044 17.6544C12.4503 17.5513 12.3302 17.405 12.2593 17.2337C12.1884 17.0625 12.1698 16.874 12.2059 16.6922C12.2421 16.5104 12.3313 16.3434 12.4623 16.2123L21.7166 6.95801C21.2377 6.69796 20.7012 6.56199 20.1562 6.5625H6.09375C5.22379 6.56343 4.38974 6.90943 3.77458 7.52458C3.15943 8.13974 2.81343 8.9738 2.8125 9.84375V23.9063C2.81343 24.7762 3.15943 25.6103 3.77458 26.2254C4.38974 26.8406 5.22379 27.1866 6.09375 27.1875H20.1562C21.0262 27.1866 21.8603 26.8406 22.4754 26.2254C23.0906 25.6103 23.4366 24.7762 23.4375 23.9063V9.84375C23.438 9.29877 23.302 8.76234 23.042 8.2834L13.7877 17.5377C13.7008 17.625 13.5976 17.6942 13.4838 17.7414C13.3701 17.7885 13.2481 17.8127 13.125 17.8125Z"
      fill={color || '#FC6244'}
    />
    <path
      d="M26.25 2.8125H19.6875C19.4389 2.8125 19.2004 2.91127 19.0246 3.08709C18.8488 3.2629 18.75 3.50136 18.75 3.75C18.75 3.99864 18.8488 4.2371 19.0246 4.41291C19.2004 4.58873 19.4389 4.6875 19.6875 4.6875H23.9865L21.7166 6.95801C22.2772 7.26249 22.7375 7.72284 23.042 8.2834L25.3125 6.01348V10.3125C25.3125 10.5611 25.4113 10.7996 25.5871 10.9754C25.7629 11.1512 26.0014 11.25 26.25 11.25C26.4986 11.25 26.7371 11.1512 26.9129 10.9754C27.0887 10.7996 27.1875 10.5611 27.1875 10.3125V3.75C27.1875 3.50136 27.0887 3.2629 26.9129 3.08709C26.7371 2.91127 26.4986 2.8125 26.25 2.8125Z"
      fill={color || '#FC6244'}
    />
  </svg>
);

export default ForwardArrow;
