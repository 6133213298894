import Title from '../../../components/Title';
import Button from '../../../components/Button';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import * as S from './style';

import { navRoutes } from '../../../constants';

const Welcome = () => {
  return (
    <S.Wrapper>
      <Title boldSection="Welcome" lightSection="to Duet" mbM="3" boldFirst />
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            Duet is a space to help therapists support your client with digital
            content in between in-person sessions and enhance therapeutic
            outcomes.
          </T.P>
        </Col>
      </Row>
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            Whether you’re a therapy organisation or independent therapist,
            create accounts for each of your therapists to manage content,
            support your clients and receive updates from your clients all in
            one place.
          </T.P>
        </Col>
      </Row>

      <S.ButtonWrapper>
        <Col w={[4, 8, 8]}>
          <Button to={navRoutes.ADMIN.SIGNUP} text="I'm Interested" />
        </Col>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default Welcome;
