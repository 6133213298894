import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../context/auth';

import Title from '../../../components/Title';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import { BasicInput } from '../../../components/Inputs';
import { dateFormatter } from '../../../helpers';
import { Basic, Expandable } from '../../../components/Cards';

import { Contents, Programmes } from '../../../api-calls';

import UpdateSection from './UpdateSection';
import FeedbackSection from './FeedbackSection';
import ExpandableProvider from '../../../context/expandable';
import { useOrg } from '../../../context/organisation';

const decideBorder = (type) => {
  switch (type) {
    case 'VIDEO':
      return 'purple';
    case 'DOCUMENT':
      return 'orange';
    case 'AUDIO':
      return 'red';
    default:
      return 'purple';
  }
};

const decideStreamable = (type, path) => {
  if (['VIDEO', 'AUDIO'].includes(type) && path) {
    return true;
  }
  return false;
};

const placeholderContent = [
  {
    file: { id: null, key: null, bucket: null },
    type: 'VIDEO',
  },
  {
    file: { id: null, key: null, bucket: null },
    type: 'AUDIO',
  },
  {
    file: { id: null, key: null, bucket: null },
    type: 'DOCUMENT',
  },
];
const IndividProgramme = () => {
  const { user } = useAuth();
  const { getOrgInfo } = useOrg();

  const [contents, setContents] = useState(user?.id ? [] : placeholderContent);
  const [update, setUpdate] = useState({});
  const [feedback, setFeedback] = useState({});
  const [therapist, setTherapist] = useState({});
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [ellipsis, setEllipsis] = useState(true);
  const [date, setDate] = useState({});
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [getError, setGetError] = useState({});
  const [password, setPassword] = useState('');

  const { id, token } = useParams();

  const getProgData = async () => {
    const { data, error } = await Programmes.getProgrammeById({
      id,
      token,
      password,
    });

    if (!error) {
      setUpdate(data.update);
      setFeedback(data.feedback);
      setTherapist(data.therapist);
      setDescription(data.description);
      setTitle(data.title);
      setDate(data.createdAt);
      setGetError({});
      setPasswordModalOpen(false);
      getOrgInfo(data.organisationId);
      return data;
    } else {
      if (error.data?.showPasswordPrompt) {
        setPasswordModalOpen(true);
      } else {
        setGetError({
          [error.data?.type]: error.message,
          general: error.message,
        });
      }
    }
  };

  const getContent = async (progId = id) => {
    const { data, error } = await Contents.getContentByProg({ id: progId });

    if (!error) {
      setContents(data);
    }
  };

  const getData = async () => {
    if (id && user?.id) {
      getContent();
      getProgData();
    } else {
      const programme = await getProgData();
      if (programme?.id) {
        getContent(programme.id);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user?.id, token]);

  const contentToView = contents.length > 0;

  return (
    <>
      <Row mb="4">
        <Col w={[4, 6, 8]}>
          <T.P small color="gray8" caps>
            {dateFormatter(date)}
          </T.P>
        </Col>
      </Row>
      <Title boldSection={title || 'Home Programme'} />
      <Row mb="7" mbT="5">
        <Col w={[4, 6, 6]}>
          <T.P
            color="gray8"
            style={{ width: '100%' }}
            ellipsis={ellipsis ? { rows: 2 } : false}
          >
            {description}
          </T.P>
          {ellipsis && (
            <T.Link onClick={() => setEllipsis(false)} mt="4" underline>
              Read more
            </T.Link>
          )}
        </Col>
      </Row>
      <Row mb="8">
        {contentToView ? (
          <ExpandableProvider itemsNumbers={contents.length}>
            {contents.map(({ type, file, ...content }, index) => (
              <Col w={[4, 6, 4]} mb="4">
                <Expandable
                  borderColor={decideBorder(type)}
                  content={{
                    download: file.url,
                    streamable: decideStreamable(type, file.url),
                    ...content,
                    categories: null,
                    type: type?.toLowerCase(),
                    url: file.url,
                  }}
                  index={index + 1}
                />
              </Col>
            ))}
          </ExpandableProvider>
        ) : (
          <Col w={[4, 6, 4]}>
            <Basic>No content to show</Basic>
          </Col>
        )}
      </Row>
      <Row>
        <UpdateSection id={id} update={update} therapist={therapist} />
        <FeedbackSection id={id} feedback={feedback} />
      </Row>

      <Modal
        visible={passwordModalOpen}
        setIsModalVisible={setPasswordModalOpen}
        preventCloseOnMask
      >
        <T.H3 mb="2">Password protected</T.H3>
        <T.P color="gray9" mb="5">
          To access this programme you need a password. Please contact your
          therapist if they have not given this to you.
        </T.P>
        <form onSubmit={handleSubmit}>
          <BasicInput
            placeholder="Type password..."
            type="password"
            value={password}
            handleChange={(value) => setPassword(value)}
            preventCloseOnMask
            mb={4}
          />
          {getError.passwordValidation && (
            <T.P color="error">{getError.passwordValidation}</T.P>
          )}
          <Button
            text="Submit"
            variant="primary"
            disabled={!password}
            onClick={handleSubmit}
          />
        </form>
      </Modal>
    </>
  );
};

export default IndividProgramme;
