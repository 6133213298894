import { useState } from 'react';
import { Col, Row } from '../../../components/Grid';
import { Dropdown } from '../../../components/Inputs';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import * as T from '../../../components/Typography';
import { organisationsStatuses } from '../../../constants/data-types';
import { navRoutes } from '../../../constants';
import { Organisations } from '../../../api-calls';

const readableName = {
  [organisationsStatuses.ACTIVE]: 'Active',
  [organisationsStatuses.DEACTIVATED]: 'Deactivated',
  [organisationsStatuses.AWAITING_APPROVAL]: 'Awaiting Approval',
};

const UserRow = ({ organisation, setError, setOrganisations }) => {
  const [actualStatus, setActualStatus] = useState(
    readableName[organisation.status]
  );

  const options = [
    { label: 'Active', value: organisationsStatuses.ACTIVE },
    { label: 'Deactivated', value: organisationsStatuses.DEACTIVATED },
  ];

  const [selected, setSelected] = useState(organisation.status);

  const [confirmActivate, setConfirmActivate] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const onCancel = () => {
    if (confirmDeactivate) {
      setConfirmDeactivate(false);
      setSelected(actualStatus);
      return;
    }
    setSelected(actualStatus);
    setConfirmActivate(false);
  };

  const handleSubmit = async () => {
    setConfirmActivate(false);
    const { data, error } = await Organisations.updateOrganisation({
      id: organisation.id,
      form: { status: organisationsStatuses.ACTIVE },
    });
    if (error) {
      setError(error.message);
    } else {
      setOrganisations((old) =>
        old.map((org) => (org.id === data.id ? data : org))
      );
      setError('');
      setActualStatus(organisationsStatuses.ACTIVE);
      setUpdateSuccess(true);
    }
  };

  const handleDeactivate = async () => {
    setConfirmDeactivate(false);
    const { error, data } = await Organisations.updateOrganisation({
      id: organisation.id,
      form: { status: 'DEACTIVATED' },
    });
    if (error) {
      setError(error.message);
    } else {
      setOrganisations((old) =>
        old.map((org) => (org.id === data.id ? data : org))
      );
      setError('');
      setActualStatus(organisationsStatuses.DEACTIVATED);
      setUpdateSuccess(true);
    }
  };

  return (
    <>
      <Row mb="6" mbM="4" ai="center">
        <Col w={[4, 2, 2]}>
          <T.Link
            color="blue"
            weight="bold"
            underline
            to={navRoutes.SUPER_ADMIN.ORGANISATION_THERAPISTS.replace(
              ':id',
              organisation.id
            )}
          >
            {organisation.name}
          </T.Link>
        </Col>
        <Col w={[4, 2, 2]}>
          <T.P color="gray9" weight="700">
            {organisation.email}
          </T.P>
        </Col>
        <Col w={[4, 4, 4]}>
          <Dropdown
            color="gray8"
            allowClear={false}
            selected={readableName[selected]}
            handleChange={(value) => {
              setSelected(value);
              if (value === organisationsStatuses.DEACTIVATED) {
                setConfirmDeactivate(true);
              } else {
                setConfirmActivate(true);
              }
            }}
            options={options.filter((e) => e.value !== selected)}
            placeholder="Select..."
          />
        </Col>
      </Row>
      <Modal
        visible={confirmActivate}
        setIsModalVisible={setConfirmActivate}
        onCancel={onCancel}
        variant="warning"
        parentFunc={() => handleSubmit('ACTIVE')}
      >
        <T.H3 mb="2" weight="bold">
          Are you sure?
        </T.H3>
        <T.P color="gray9" mb="5">
          This will give them access to the platform as an approved
          organisation.
        </T.P>
        <Button
          handleClick={handleSubmit}
          text="Confirm"
          mb="3"
          customColor="pink"
        />
        <Button handleClick={onCancel} text="Go back" m="0" variant="gray" />
      </Modal>

      <Modal
        visible={confirmDeactivate}
        setIsModalVisible={setConfirmDeactivate}
        onCancel={onCancel}
        variant="warning"
      >
        <T.H3 mb="2" weight="bold">
          Are you sure?
        </T.H3>
        <T.P color="gray9" mb="5">
          This will prevent them and all their therapists from being able to log
          into their account.
        </T.P>
        <Button
          handleClick={handleDeactivate}
          text="Confirm"
          mb="3"
          customColor="pink"
        />
        <Button handleClick={onCancel} text="Go back" m="0" variant="gray" />
      </Modal>
      <Modal
        type="updateSuccess"
        title="Successfully updated"
        description="All your changes have been saved."
        visible={updateSuccess}
        setIsModalVisible={setUpdateSuccess}
        titleProps={{ weight: 'bold' }}
      />
    </>
  );
};

export default UserRow;
