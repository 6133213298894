import styled from '@emotion/styled';
import { H4 } from '../Typography';
import { Link as RouterLink } from 'react-router-dom';
import { Drawer as AntDrawer } from 'antd';
import { Collapse as AntCollapse } from 'antd';

import theme from '../../theme';
const { Panel } = AntCollapse;

export const Div = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    margin-top: ${({ theme: { spacings } }) => ` ${spacings[6]}`};
  }
`;

export const Head4 = styled(H4)`
  display: inline;
`;

export const Link = styled(RouterLink)`
  padding: 12px;
  white-space: nowrap;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-body {
    position: relative;
    padding: 30px 24px 0 38px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: ${({ theme }) => theme.spacings[1]};
      background: ${({ theme }) => theme.gradients.random};
    }
  }
`;

export const RainbowContainer = styled.div`
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  z-index: 1;
`;
export const Collapse = styled(AntCollapse)`
  // padding: inherit;
  width: 100%;
`;
export const AntPanel = styled(Panel)`
  background-color: ${theme.colors.gray9};
  color: ${theme.colors.white};
  height: 52px;
  .ant-collapse-content-box {
    background-color: ${theme.colors.gray9};
    color: ${theme.colors.white};
    position: absolute;
    height: 200%;
    width: 100vw;
    background-color: ${theme.colors.gray9};
    margin-left: -38px;
    padding-left: ${({ theme: { spacings } }) => ` ${spacings[7]}`};
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    padding: 0 !important;
  }
`;
export const CollapseLink = styled(RouterLink)`
  font-family: Lato;
  font-weight: bold;
  font-size: 1rem;
  line-height: 150%;
  width: 150px;
  color: ${theme.colors.white};
`;

export const InnerDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  background-color: ${theme.colors.gray9};
  margin-left: -38px;
`;
