import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import Copy from '../../../components/Copy';
import { Organisations } from '../../../api-calls';

import * as S from './style';

import { navRoutes } from '../../../constants';
import { useOrg } from '../../../context/organisation';
import { useAuth } from '../../../context/auth';

const Approve = () => {
  const { org, setOrg } = useOrg();
  const { user } = useAuth();
  const history = useHistory();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onClickHome = async () => {
    setLoading(true);
    const { error } = await Organisations.updateOrganisation({
      id: user.organisationId,
      form: { visitedSinceApproval: true },
    });
    setLoading(false);

    if (!error) {
      setOrg({ ...org, visitedSinceApproval: true });
      history.push(navRoutes.ADMIN.DASHBOARD);
    } else {
      setError(error.message);
    }
  };

  return (
    <S.Wrapper>
      <Title boldSection="Approved!" mbM="3" boldFirst />
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">Your account has been approved!</T.P>
        </Col>
      </Row>
      <Row mb="6" mbM="4">
        <Col w={[4, 12, 12]}>
          <T.P color="gray8">
            To begin getting your therapists onto the platform, please send them
            the link below and ask for them to sign up.
          </T.P>
        </Col>
      </Row>
      <Row mb="6" mbM="4">
        <Col w={[4, 8, 12]}>
          {error && <T.P color="error">{error}</T.P>}
          <Copy
            inviteToken={`${
              window.location.origin
            }${navRoutes.THERAPIST.SIGNUP.replace(
              ':uniqueSlug',
              org.uniqueSlug || ''
            )}`}
          />
        </Col>
      </Row>

      <S.ButtonWrapper>
        <Col w={[4, 8, 8]}>
          <Button
            to={navRoutes.ADMIN.DASHBOARD}
            handleClick={onClickHome}
            text="Home"
            loading={loading}
          />
        </Col>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default Approve;
