import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Col, Row } from '../../components/Grid';
import { Link } from '../../components/Cards';
import { Dropdown } from '../../components/Inputs';
import { THERAPIST } from '../../constants/nav-routes';
import * as T from '../../components/Typography';
import Button from '../../components/Button';
import { Basic } from '../../components/Cards';
import { TherapistClients } from '../../api-calls';
import { useAuth } from '../../context/auth';

import * as S from './style';
import { userRoles } from '../../constants';

const generateOptions = (data) =>
  data?.length > 0
    ? data.map((client) => ({
        label: `${client.firstInitial} ${client.lastInitial} ${client.postcode}`,
        value: client.id,
      }))
    : [];

const decideBorderColor = (n) => {
  const a = n % 4;
  if (a === 0) return 'purple';
  if (a === 1) return 'orange';
  if (a === 2) return 'green';
  if (a === 3) return 'red';
};

const ClientsSection = ({ toFeedback, setIsModalVisible, watchedTutorial }) => {
  const [clients, setClients] = useState([]);
  const [therapists, setTherapists] = useState([]);
  const [selectedTherapistId, setSelectedTherapistId] = useState(null);

  const history = useHistory();
  const { user } = useAuth();
  const [viewClients, setViewClients] = useState(5);
  const handleChange = (clientId) => {
    history.push(
      toFeedback
        ? THERAPIST.CLIENT_FEEDBACK.replace(':id', clientId)
        : THERAPIST.CLIENT.replace(':id', clientId)
    );
  };

  useEffect(() => {
    const getClients = async () => {
      const { data, error } = await TherapistClients.getClients();

      if (!error) {
        setClients(data);

        if (
          user.role === userRoles.ADMIN ||
          user.role === userRoles.SUPER_ADMIN
        ) {
          let therapistsObj = {};
          data.forEach((client) => {
            therapistsObj[client.therapist.id] = {
              label: `${client.therapist.firstName} ${client.therapist.lastName}`,
              value: client.therapist.id,
            };
          });

          setTherapists(Object.values(therapistsObj));
        }
      }
    };

    if (user?.id) {
      getClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const filteredClientsByTherapist = clients.filter((client) => {
    if (!selectedTherapistId) return true;
    if (!client?.therapist?.id) return true;
    return client?.therapist?.id === selectedTherapistId;
  });

  const clientsToView = filteredClientsByTherapist.length > 0;

  return (
    <>
      <Row>
        <Col w={[4, 4, 4]} mt={6}>
          <Dropdown
            label="Search client"
            placeholder="Search..."
            options={generateOptions(filteredClientsByTherapist)}
            handleChange={handleChange}
            search
          />
        </Col>
        {user.role !== userRoles.THERAPIST && (
          <Col w={[4, 4, 4]} mt={6}>
            <Dropdown
              label="Filter by therapist"
              placeholder="Select one... "
              options={therapists}
              handleChange={setSelectedTherapistId}
            />
          </Col>
        )}
      </Row>
      <Row mt={5} mtT={2}>
        {clientsToView ? (
          filteredClientsByTherapist
            .slice(0, viewClients)
            .map((client, index) => {
              return (
                <Col w={[4, 4, 4]} mt={4} key={client.id}>
                  <S.CardWrapper>
                    <Link
                      variant="client"
                      title="therapy plan"
                      client={client}
                      to={
                        toFeedback
                          ? THERAPIST.CLIENT_FEEDBACK.replace(':id', client.id)
                          : THERAPIST.CLIENT.replace(':id', client.id)
                      }
                      borderColor={decideBorderColor(index)}
                    />
                  </S.CardWrapper>
                </Col>
              );
            })
        ) : (
          <Col w={[4, 4, 4]}>
            <Basic variant="noClients" />
          </Col>
        )}
      </Row>

      {viewClients < filteredClientsByTherapist.length && (
        <Row>
          <Col w={[4, 12, 12]} jc="flex-start" jcT="center">
            <T.Link
              weight="bold"
              to={false}
              mt={6}
              underline
              onClick={() => setViewClients((_old) => _old + 5)}
            >
              View more
            </T.Link>
          </Col>
        </Row>
      )}
      {!toFeedback && (
        <Row mt={8} mtT={6}>
          <Col w={[4, 4, 4]}>
            <Button
              text="Add a new client"
              variant="primary"
              onClick={() =>
                !clientsToView && !watchedTutorial
                  ? setIsModalVisible(true)
                  : history.push(THERAPIST.NEW_CLIENT)
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ClientsSection;
