import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { THERAPIST } from '../../../constants/nav-routes';

import { Cards, Typography as T, Grid, Modal } from '../../../components/';

import { useAuth } from '../../../context/auth';
import ClientsSection from './../../../components/ClientsSection';

import * as S from './style';

const { Link } = Cards;

const { Col, Row } = Grid;

const Dashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();

  const parentFunc = () => {
    history.push(THERAPIST.HOW_IT_WORKS);
  };

  const { user } = useAuth();

  return (
    <div style={{ maxWidth: 1068 }}>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2>
            Welcome back, <S.BoldSpan>{user.firstName}</S.BoldSpan>!
          </T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]} mt={7} mtT={5}>
          <T.H3>My clients</T.H3>
        </Col>
      </Row>
      <ClientsSection
        setIsModalVisible={setIsModalVisible}
        watchedTutorial={user.watchedTutorial}
      />
      <Row mt={9} mtT={8}>
        <Col w={[4, 6, 4]} mt={5}>
          <S.CardWrapper>
            <Link variant="graphic1" title="library" to={THERAPIST.LIBRARY} />
          </S.CardWrapper>
        </Col>
        <Col w={[4, 6, 4]} mt={5}>
          <S.CardWrapper>
            <Link variant="graphic2" title="account" to={THERAPIST.ACCOUNT} />
          </S.CardWrapper>
        </Col>
      </Row>
      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        parentFunc={parentFunc}
        type="needToComplete"
      />
    </div>
  );
};

export default Dashboard;
